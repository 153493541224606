import React, { useCallback, useState, useContext } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { openModal } from 'actions/ui';
import colors from 'styles/colors.json';
import Stripe from '../../helpers/stripe';
import RaisedButton from 'material-ui/RaisedButton';
import { stripeAddBankAccount, stripeAddExternalBankAccount } from '../../actions/ledger';
import get from 'lodash/get';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const styles = {
  labelStyle: {
    fontSize: '20',
    textTransform: 'capitalize !important',
  },
}

function AddStripeBankAccount({ openModal, companyId, accountId, context, isBroker = false }) {
  const { setReloadPage } = useContext(context);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const addBankAccountHandler = useCallback(async event => {
    event.preventDefault();
    setIsProcessing(true);
    try {
      const res = await stripeAddBankAccount(companyId);
      const params = {
        payment_method_type: 'us_bank_account',
        payment_method_data: {
          billing_details: {
            name: res.owner_name || res.company_name,
            email: res.email,
          },
        },
      }
      const options = {
        expand: ['payment_method'],
      }
      const { setupIntent, error } = await Stripe
        .getInstance(res.stripe_account_id)
        .collectBankAccountForSetup(res.client_secret, params, options);
      if (error) {
        // Inform your user that there was an error, check your request logs for errors
        setIsProcessing(false);
        return openModal('error', { message: error.message });
      } else if (setupIntent.status === 'requires_payment_method') {
        // Customer canceled the hosted verification modal. Present them with other
        // payment method type options.
      } else if (setupIntent.status === 'requires_confirmation') {
        // We collected an account - possibly instantly verified, but possibly
        // manually-entered. Display payment method details and mandate text
        // to the customer and confirm the intent once they accept
        // the mandate.
        openModal('confirm', {
          type: 'stripeBankAccount_confirm',
          args: [{
            onConfirm: () => {
              Stripe
                .getInstance(res.stripe_account_id)
                .confirmUsBankAccountSetup(setupIntent.client_secret, {
                  payment_method: get(setupIntent, 'payment_method.id'),
                }
                )
                .then(async ({ setupIntent: setupIntentConfirmation, error }) => {
                  if (error) {
                    // The payment failed for some reason.
                    setIsProcessing(false);
                    return openModal('error', { message: error.message });
                  } else if (setupIntentConfirmation.status === "requires_payment_method") {
                    console.error("requires_payment_method");
                    // Confirmation failed. Attempt again with a different payment method.
                  } else if (setupIntentConfirmation.status === "succeeded") {
                    // Confirmation succeeded! The account is now saved.
                    // Display a message to customer.
                    openModal('success', { message: 'Bank account successfully added.' });
                    await stripeAddExternalBankAccount(setupIntentConfirmation.payment_method, accountId);
                    setTimeout(() => setReloadPage(), 1000)
                  } else if (setupIntentConfirmation.next_action?.type === "verify_with_microdeposits") {
                    // The account needs to be verified via microdeposits.
                    // Display a message to consumer with next steps (consumer waits for
                    // microdeposits, then enters a statement descriptor code on a page sent to them via email).
                  }
                });
            },
          }],
        });
      }
    } catch (error) {
      openModal('error', { message: error });
    }
    setIsProcessing(false);
  }, []);

  const confirmStripeMicroDeposit = () => openModal('ConfirmMicroDeposit', { onSuccess: () => setTimeout(() => setReloadPage(), 1000) });

  return <>
    <RaisedButton
      label='Add Bank Account'
      icon={isProcessing && <FontAwesomeIcon
        icon={faSpinner}
        size="1x"
        spin
        color={colors.WHITE}
        style={{ marginLeft: 5 }}
      />}
      labelColor={colors.WHITE}
      backgroundColor={colors.LIGHT_GREEN}
      labelStyle={styles.labelStyle}
      disabled={isProcessing}
      onClick={addBankAccountHandler}
    />
    <div className='separator'>
      <span>OR</span>
    </div>
    {isBroker && 
      <RaisedButton
        label='Received Micro Deposit'
        labelColor={colors.WHITE}
        backgroundColor={colors.ORANGE}
        labelStyle={styles.labelStyle}
        disabled={isProcessing}
        onClick={confirmStripeMicroDeposit}
      />
    }
  </>
}

export default compose(
  connect(state => ({
    companyId: get(state.resource, ['user', state.user.id, 'data', 'factoring_id'])
  }), { openModal })
)(AddStripeBankAccount)