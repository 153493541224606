var content = require("!!../../../../node_modules/@nrwl/web/src/utils/third-party/cli-files/plugins/raw-css-loader.js!../../../../node_modules/@nrwl/web/node_modules/postcss-loader/dist/cjs.js??ref--5-oneOf-5-2!../../../../node_modules/sass-loader/dist/cjs.js??ref--5-oneOf-5-3!./Header.scss");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {}

options.insert = "head";
options.singleton = false;

var update = require("!../../../../node_modules/@nrwl/web/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
