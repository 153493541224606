import React from 'react';
import {
    Typography,
    Row,
    Col,
    Input,
    Select,
    Button,
    Switch,
    Table,
} from 'antd';
import Colors from '../../../../styles/colors.json';
import { PlusOutlined } from '@ant-design/icons';
import formatPennies from '../../../helpers/formatPennies';
import USER_FACTORING_STATUS from '../../../helpers/USER_FACTORING_STATUS';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import data from './data';

const { Option } = Select;
const searchOptions = [
    {
        text: 'Carrier Name',
        value: 'carrier_dba',
    },
    {
        text: 'Status',
        value: 'status',
    },
    {
        text: 'Email',
        value: 'email',
    },
    {
        text: 'MC #',
        value: 'mc',
    },
    {
        text: 'DOT #',
        value: 'dot',
    },
    // {
    //   text: 'Tax ID #',
    //   value: 'tax_id',
    // },
    {
        text: 'Phone',
        value: 'phone',
    },
];

const NOAStatus = ({ data }) => {
    const P = styled.p(() => [
        tw`text-red-600`,
        data.noa_active && tw`text-green-500`,
        data.noa_sent && tw`text-blue-300`,
        data.noa_requested && tw`text-yellow-400`,
    ]);
    if (data.noa_active) {
        return <P>Placed (Active)</P>;
    }

    if (data.noa_sent) {
        return <P>Sent (Pending)</P>;
    }

    if (data.noa_requested) {
        return <P>Request Sent</P>;
    }

    return (
        <P>{`Not Sent ${
            data.credit_approved === 'approved' ? '(Send?)' : ''
        }`}</P>
    );
};

const checkStatus = (status) => {
    switch (status) {
        case USER_FACTORING_STATUS.PENDING_DELIVERY:
            return { text: 'Incomplete', value: status };
        case USER_FACTORING_STATUS.REMOTE_APPROVED:
            return { text: 'Approved', value: status };
        case USER_FACTORING_STATUS.INCOMPLETE:
            return { text: 'pending_delivery', value: 'pending_delivery' };
        case USER_FACTORING_STATUS.SPECIAL_PENDING:
            return {
                text: 'Pending',
                value: USER_FACTORING_STATUS.SPECIAL_PENDING,
            };
        case USER_FACTORING_STATUS.PENDING:
            return {
                text: 'Pending',
                value: USER_FACTORING_STATUS.SPECIAL_PENDING,
            };
        case USER_FACTORING_STATUS.CARRIER_PAID:
            return {
                text: 'Carrier Paid',
                value: USER_FACTORING_STATUS.CARRIER_PAID,
            };
        case USER_FACTORING_STATUS.INVOICE_PAID:
            return {
                text: 'Invoice Paid',
                value: USER_FACTORING_STATUS.INVOICE_PAID,
            };
        default:
            return { text: status, value: status };
    }
};

const columns = [
    {
        title: 'Carrier',
        render: (data) => data.payment_profile.company_name,
        key: 'company_name',
    },
    {
        title: 'Debtor',
        render: (data) => data.debtor.dba,
        key: 'dba',
    },
    {
        title: 'NOA Status',
        render: (data) => <NOAStatus data={data.factoring_debtor_relation} />,
        key: 'noa',
    },
    {
        title: 'Invoice Amnt.',
        render: (data) => formatPennies(data.amount),
        key: 'amount',
    },
    {
        title: 'Invoice #',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
    },
    {
        title: 'NOA Status',
        render: (data) => {
            const invoiceSent = `Invoice Sent On: \n
          ${moment(data.invoice_date).format('l LTS')}
        `;
            const P = styled.p(() => [
                tw`text-red-600`,
                data.invoice_date && tw`text-green-500`,
            ]);

            return data.invoice_date ? (
                <div>
                    <Tooltip tooltipText={invoiceSent} placement="top">
                        <P>YES</P>
                    </Tooltip>
                </div>
            ) : (
                <P>NO</P>
            );
        },
        key: 'noa',
    },
    {
        title: 'Load #',
        dataIndex: 'user_load_number',
        key: 'user_load_number',
    },
    {
        title: 'Requested',
        render: (data) => moment(data.created).format('l LTS'),
        key: 'requested',
    },
    {
        title: 'Debtor Rate',
        render: (data) => data.factoring_debtor_relation.discount_rate || '-',
        key: 'discount_rate',
    },
    {
        title: 'Carrier %',
        render: (data) =>
            `${
                (data.discount_rate * (100 - data.factoring_fee_split_rate)) /
                100
            }%` || '-',
        key: 'carrier_discount_rate',
    },
    {
        title: 'Carrier Pay',
        render: (data) =>
            data.payment_profile_amount
                ? formatPennies(data.payment_profile_amount)
                : '-',
        key: 'payment_profile_amount',
    },
    {
        title: 'Amnt. Funded.',
        render: (data) =>
            data.amount_funded ? formatPennies(data.amount_funded) : '-',
        key: 'payment_profile_amount',
    },
    {
        title: 'Status',
        render: (data) => {
            const status = data.status;
            const nonFactoredValueMap = {
                bill_out: 'Bill Out Only',
                standard: 'Pay Carrier',
            };
            const B = styled.b(() => [
                tw`uppercase`,
                [
                    'declined',
                    'incomplete',
                    'pending_delivery',
                    'document_issue',
                ].includes(status) && tw`text-red-600`,
                [
                    'approved',
                    'paid',
                    'remote_approved',
                    'invoice_paid',
                    'carrier_paid',
                ].includes(status) && tw`text-green-500`,
                ['new'].includes(status) && tw`text-blue-300`,
                ['pending', 'special_pending', 'fuel_advance'].includes(
                    status
                ) && tw`text-yellow-400`,
            ]);
            return (
                <div>
                    {data.attachments.length > 0 &&
                        data.attachments[data.attachments.length - 1]
                            .after_issue === true && (
                            <div className="text-success">
                                <FontAwesomeIcon icon={faPaperclip} size="xs" />
                                New!
                                <br />(
                                {moment(
                                    data.attachments[
                                        data.attachments.length - 1
                                    ].uploaded
                                ).format('MM/DD, hh:mm a')}
                                )
                            </div>
                        )}{' '}
                    <B>{checkStatus(status).text}</B>
                    {data.non_factored_type && (
                        <p>{nonFactoredValueMap[data.non_factored_type]}</p>
                    )}
                </div>
            );
        },
        key: 'status',
    },
    {
        title: 'More',
        render: () => <Button type="primary">More</Button>,
    },
];

const TableComponent = tw.table`divide-y divide-gray-200`;
const TableHeader = tw.thead`bg-shaddow`;
const HeaderColumn = tw.th`text-left font-bold text-white uppercase tracking-wider`;
const TableColumn = tw.td`whitespace-nowrap text-gray-500 `;
const TableRow = styled.tr((props) => [
    tw`border-b-2`,
    props['data-row-key'] % 2 === 0 && props.striped
        ? tw`bg-gray-200`
        : tw`bg-white`,
]);

function MyCarriersBrokers() {
    return (
        <div style={{ margin: 25 }}>
            <Row>
                <Col span={12}>
                    <Typography.Title level={3}>
                        My Carriers (20)
                    </Typography.Title>
                </Col>
                <Col span={12}>
                    <Row justify="end" gutter={[8, 8]}>
                        <Col>
                            <Input placeholder="Search" />
                        </Col>
                        <Col>
                            <Select
                                defaultValue="carrier_dba"
                                style={{ width: 120 }}
                                onChange={(value) =>
                                    console.log('ON CHANGE', value)
                                }
                            >
                                {searchOptions.map((option) => {
                                    return (
                                        <Option value={option.value}>
                                            {option.text}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                size="middle"
                                style={{
                                    background: Colors.ORANGE,
                                    borderColor: 'transparent',
                                }}
                            >
                                Search
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                size="middle"
                                style={{
                                    background: Colors.DARK_GREEN,
                                    borderColor: 'transparent',
                                }}
                                icon={<PlusOutlined />}
                            >
                                Add New
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                size="middle"
                                style={{
                                    background: Colors.BLUE_BTN,
                                    borderColor: 'transparent',
                                }}
                            >
                                Defaults
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <div style={{ display: 'flex' }}>
                    <Switch
                        defaultChecked
                        style={{ background: Colors.ORANGE }}
                    />
                    <div style={{ marginLeft: 20 }}>
                        <Typography.Paragraph>
                            Generate a carrier rate confirmation from
                            HaulPay, email to carrier.
                        </Typography.Paragraph>
                    </div>
                </div>
            </Row>
            <Row>
                <Table
                    striped
                    size="small"
                    columns={columns}
                    dataSource={data}
                    components={{
                        table: TableComponent,
                        header: {
                            wrapper: TableHeader,
                            cell: HeaderColumn,
                        },
                        body: {
                            row: TableRow,
                            cell: TableColumn,
                        },
                    }}
                />
            </Row>
        </div>
    );
}

export default MyCarriersBrokers;
