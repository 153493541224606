import React from 'react';
import {
  compose,
  lifecycle,
  shallowEqual,
  withHandlers,
  shouldUpdate,
  withState,
  withStateHandlers,
} from 'recompose';
import { Field, reduxForm, submit, isSubmitting, FieldArray } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Toggle from 'material-ui/Toggle';
import { openModal, closeModal } from 'actions/ui';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import DebtorAutocompleteInput from 'components/pure/form/inputs/DebtorAutocompleteInput';
import CarrierNameAutocompleteInput from 'components/pure/form/inputs/CarrierNameAutocompleteInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import EquipmentType from 'components/pure/form/inputs/EquipmentInput';
import Attachments from 'components/container/FactoringPayments/FactoringBrokerPayments/Attachments';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import remove from 'lodash/remove';
import isUndefined from 'lodash/isUndefined';
import has from 'lodash/has';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import { Button } from 'react-bootstrap';
import ImageCropper from 'components/pure/ImageCropper';
import validate from 'validate.js';
import { formattedData } from 'components/pure/form/inputs/PaymentSpeedDropDown';
import MaterialIcon from '../MaterialIcon';
import Tooltip from 'components/pure/Tooltip';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import { FUNDING_REQUEST_CONTRACT_TYPES } from 'helpers';
import CreateFuelAdvanceForm, {
  FORM_NAME as FORM_CREATE_FUEL_ADVANCE
} from './CreateFuelAdvanceForm';
import { fetchFactoringProfile } from '../../../actions/user';
import { fundingRequestCalculations, PurchaseOrderNumberRelatedInvoices } from '../../../actions/factoring';
import BackgroundSpinner from '../BackgroundSpinner';
import UploadAttachments from '../../container/FactoringPayments/FactoringBrokerPayments/UploadAttachments';
import LineItemsForm from 'components/pure/form/LineItemsForm';
import formatPennies from '../../../helpers/formatPennies';
import { CoBrokeringWarning, NonSelfFinanceFee, SelfFinanceFee } from './DebtorCarrierPaymentForm';

const styles = {
  labelText: {
    fontSize: '1.20em',
    textTransform: 'capitalize'
  },
  feeStyleBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textStyle: {
    fontSize: 10,
    color: '#498504'
  }
};

const FundingRequestType = props => {
  if(props.useNewNFRequest) {
      props.enabledContractTypes['NON_FACTORED_BILL_OUT'] = true;
      props.enabledContractTypes['NON_FACTORED_STANDARD'] = true;
  }
  const disabledValue = reduce(props.enabledContractTypes, (results, value, key) => !value ? concat(results, [key]) : results, []);

  const data = [{
    text: 'Factored',
    value: 'STD_BROKER',
    type: 'Standard Funding Requests'
  }, {
    text: 'Non-Factored Pay Carrier',
    value: 'NON_FACTORED_STANDARD',
    type: 'Non Factored Funding Requests'
  }, {
    text: 'Non-Factored Bill Out Only',
    value: 'NON_FACTORED_BILL_OUT',
    type: 'Non Factored Funding Requests'
  }, {
    text: 'Self-Financed No Invoicing',
    value: 'SELF_FINANCE_NO_INVOICE',
    type: 'Non Factored Funding Requests'
  }, {
    text: 'Self-Financed With Invoicing',
    value: 'SELF_FINANCE_WITH_INVOICE',
    type: 'Non Factored Funding Requests'
  }];

  return (
    <Field
      disabled={disabledValue}
      name='funding_request_type'
      component={LabeledDropdown}
      label='Funding Request Type'
      data={data}
      groupBy={FRType => FRType.type}
      onValueChange={value => {
        switch (value) {
          case 'NON_FACTORED_STANDARD': // ex 'pay-carrier'
            props.change('self_finance_requested', false);
            !props.useNewNFRequest && props.change('status', 'non_factored_carrier');
            props.change('contract_type', 'NON_FACTORED_STANDARD');
            props.change('factored', false);
            break;
          case 'NON_FACTORED_BILL_OUT': // ex 'bill-put-only'
            props.change('self_finance_requested', false);
            !props.useNewNFRequest && props.change('status', 'non_factored');
            props.change('contract_type', 'NON_FACTORED_BILL_OUT');
            props.change('factored', false);
            break;
          case 'SELF_FINANCE_NO_INVOICE': // ex 'self-financed'
            props.change('self_finance_requested', true);
            props.change('apply_reserve_holdback', false);
            props.change('status', props.status);
            props.change('contract_type', 'SELF_FINANCE_NO_INVOICE');
            props.change('factored', true);
            break;
          case 'SELF_FINANCE_WITH_INVOICE': // ex 'self-financed-invoicing'
            props.change('self_finance_requested', true);
            props.change('apply_reserve_holdback', false);
            props.change('status', props.status);
            props.change('contract_type', 'SELF_FINANCE_WITH_INVOICE');
            props.change('factored', true);
            break;
          default:
            props.change('self_finance_requested', false);
            props.change('apply_reserve_holdback', false);
            props.change('status', props.status);
            props.change('contract_type', 'STD_BROKER');
            props.change('factored', true);
            break;

        }

      }}
      {...props}
    />
  );
};

const RateField = props => {
  const data = [
    {
      text: '0%',
      value: 0
    },
    {
      text: '50%',
      value: 50
    },
    {
      text: '100%',
      value: 100
    }
  ];

  const ListItem = ({ item: { text, fee } }) => <div>{text}</div>;

  return (
    <Field
      {...props}
      component={LabeledDropdown}
      data={data}
      itemComponent={ListItem}
    />
  );
};

const StatusField = props => {
  const { status, isFuelAdvanceStatus, useNewNFRequest } = props;
  let disabledStatus = Array();
  if(!isFuelAdvanceStatus) {
    disabledStatus.push('fuel_advance');
  }
  if (['non_factored', 'non_factored_carrier'].includes(props.updatedStatus)){
    disabledStatus.push('special_pending');
    disabledStatus.push('declined');
    disabledStatus.push('document_issue');
    disabledStatus.push('remote_approved');
  }
  disabledStatus.push('carrier_paid');
  disabledStatus.push('invoice_paid');
  const data = [
    {
      text: 'Incomplete',
      value: 'pending_delivery'
    },
    {
      text: 'Pending',
      value: 'special_pending'
    },
    {
      text: 'Declined',
      value: 'declined'
    },
    {
      text: 'Document Issue',
      value: 'document_issue'
    },
    {
      text: 'Approved',
      value: 'remote_approved'
    },
    ...(() =>
      (['pending_delivery', 'non_factored', 'non_factored_carrier'].includes(status) && !props.selfFinanceRequested && !useNewNFRequest)
        ? [
          {
            text: 'Non-Factored Bill Out Only',
            value: 'non_factored'
          },
          {
            text: 'Non-Factored Pay Carrier',
            value: 'non_factored_carrier'
          }
        ] : [])(),
    ...((isFuelAdvanceStatus && !props.selfFinanceRequested) ?
      [{
        text: 'Fuel Advance',
        value: 'fuel_advance'
      }] : []
      )
   ,
    ...(['carrier_paid', 'invoice_paid'].includes(status) ?
      [{
      text: 'Carrier Paid',
      value: 'carrier_paid'
    },
    {
      text: 'Invoice Paid',
      value: 'invoice_paid'
        }] : []
    ),
    ...(['haulpay_review'].includes(status) ?
      [{
      text: 'HaulPay Review',
      value: 'haulpay_review'
    }]: [])
  ];

  return (
    <Field
      {...props}
      disabled={props.disableAll || disabledStatus}
      component={LabeledDropdown}
      data={data}
    />
  );
};

const FactoringPaymentEditFormBroker = compose(
  withStateHandlers(
    ({ loading = false, display = false, formattedItems = [], formWarning = {}, poRelatedInvoice = [] }) => ({
      loading,
      display,
      formattedItems,
      formWarning,
      poRelatedInvoice,
    }),
    {
      setLoading: props => loading => ({ loading }),
      setDisplay: props => display => ({ display }),
      setFormattedItems: props => formattedItems => ({ formattedItems }),
      setFormWarning: props => formWarning => ({ formWarning }),
      setPoRelatedInvoice: props => poRelatedInvoice => ({ poRelatedInvoice }),
    }
  ),
  withHandlers({
    getCalculation: props => async formValues => {
      try {
        props.setLoading(true);
        const isNonFactoredFundingRequest = [FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_STANDARD, FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_BILL_OUT].includes(formValues.contract_type)
          || [USER_FACTORING_STATUS.NON_FACTORED, USER_FACTORING_STATUS.NON_FACTORED_CARRIER].includes(formValues.status)
        const factored = !isNonFactoredFundingRequest || formValues.factored;
        const data = {
          // funding_request_id: props.factoring_id,
          payment_profile_amount: formValues.payment_profile_amount || 0,
          payment_profile_invoice_amount: formValues.payment_profile_amount || 0,
          invoice_amount: formValues.amount,
          debtor: formValues.debtor.id,
          self_finance_requested: formValues.self_finance_requested,
          factored,
          apply_reserve_holdback: formValues.apply_reserve_holdback,
          contract_type: formValues.funding_request_type,
          factoring_fee_split_rate: Number(formValues.factoring_fee_split_rate),
          create_rates_revision: false,
          ...(() =>
            factored
              ? {
                payment_speed: parseInt(
                  formValues.payout_days.match(/\d+/g)[0],
                  0
                ),
                payment_profile: formValues.carrier.payment_profile_id,
                factoring_fee_split_rate: Number(formValues.factoring_fee_split_rate)
              }
              : {
                // factoring_fee_split_rate: 100
              })()
        };
        const res = await props.dispatch(fundingRequestCalculations(data));
        props.change('payment_profile_purchase_amount', res.payment_profile_payable);
        props.change('Carrier_fee', res.payment_profile_fee);
        props.change('factoring_discount_amount', res.factoring_fee);
        props.change('carrier_rate', res.payment_profile_discount_rate);
        props.change('reserve_holdback', res.reserve_amount);
        props.change('factoring_unpaid_purchase_amount', res.factoring_payable);
        props.change('factoring_fee_split_rate', Number(res.factoring_fee_split_rate));
        props.change('is_reserve_holdback', res.is_reserve_holdback);
        props.change('total_comfreight_fee', res.total_comfreight_fee);
        if (formValues.self_finance_requested) {
          props.change('carrier_line_haul', res.carrier_line_haul);
          props.change('qp_total_fee', res.qp_total_fee);
          props.change('carrier_qp_fee', Math.abs(res.carrier_qp_fee));
          props.change('carrier_total_amount', res.carrier_total_amount);
          props.change('broker_self_finance_fee', res.broker_self_finance_fee);
          props.change('broker_qp_profit', Math.abs(res.broker_qp_profit));
          props.change('self_finance_total_amount', res.self_finance_total_amount);
          props.change('self_finance_fee_rate_info', res.self_finance_fee_rate_info);
          props.change('discount_rate_comfreight', res.discount_rate_comfreight);
        }
        setInterval(() => {
          props.setLoading(false);
        }, 1000);
      } catch (e) {
        props.setLoading(false);
      }
    }
  }),
  lifecycle({
    async componentDidMount() {
      const { fetchTerms, self, dispatch } = this.props;
      await dispatch(fetchFactoringProfile());
      await fetchTerms(self.data.factoring_id);
    },
    async componentDidUpdate(prevProps, prevState) {
      const {
        formValues,
        terms,
        setFormattedItems,
        getCalculation,
        setLoading,
        setDisplay,
        dispatch,
        setFormWarning,
        setPoRelatedInvoice,
        updatedFormValues,
      } = this.props;
      if (terms !== prevProps.terms) {
        setFormattedItems(formattedData(terms));
      }
      if (formValues.amount !== get(prevProps, 'formValues.amount', 0)
        || formValues.funding_request_type !== get(prevProps, 'formValues.funding_request_type', '')) {
        getCalculation(formValues);
        }
      if (updatedFormValues.purchase_order_number && (get(prevProps, 'updatedFormValues.purchase_order_number') != updatedFormValues.purchase_order_number)) {
        const res = await dispatch(PurchaseOrderNumberRelatedInvoices(formValues.purchase_order_number, this.props.params.id));
        if(!isEmpty(res)) {
          setFormWarning({ purchase_order_number: <p className='text-danger'>This PO# has been used on another invoice <a href={void (0)} onClick={() => dispatch(openModal('PONumberInvoiceModal', { poRelatedInvoice: res }))}>Click Here</a> to view</p> });
          setPoRelatedInvoice(res);
        } else {
          setFormWarning({});
          setPoRelatedInvoice([]);
        }
      }
    }
  }),
  shouldUpdate((props, nextProps) => {
    return (
      props.loading !== nextProps.loading ||
      !shallowEqual(props.formWarning, nextProps.formWarning) ||
      !shallowEqual(props.poRelatedInvoice, nextProps.poRelatedInvoice) ||
      !shallowEqual(props.updatedFormValues, nextProps.updatedFormValues) ||
      !shallowEqual(props.terms, nextProps.terms) ||
      !shallowEqual(props.refresh, nextProps.refresh) ||
      get(props, 'item.data.attachments', []).length !==
      get(nextProps, 'item.data.attachments', []).length ||
      get(nextProps, 'item.data.attachments', []).length && (get(
        props,
        `item.data.attachments[${get(props, 'item.data.attachments', [])
          .length - 1}].uploaded`,
        []
      ) !==
        get(
          nextProps,
          `item.data.attachments[${get(nextProps, 'item.data.attachments', [])
            .length - 1}].uploaded`,
          []
        )) ||
      !shallowEqual(
        get(props, 'attachmentToCrop', {}),
        get(nextProps, 'attachmentToCrop', {})
      ) ||
      !shallowEqual(
        get(props, 'deletedAttachments', []),
        get(nextProps, 'deletedAttachments', [])
      ) ||
      props.invoiceCount !== nextProps.invoiceCount ||
      get(props, 'formValues.apply_reserve_holdback') !== get(nextProps, 'formValues.apply_reserve_holdback') ||
      !shallowEqual(props.newAttachments.length, nextProps.newAttachments.length)
    );
  }),
  reduxForm({
    form: 'FactoringPaymentEditFormBroker',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: true,
    asyncBlurFields: ['attachments', 'visibilty_to'],
    async asyncValidate(fields, dispatch, props, blurredField) {
      const attachments = get(fields, 'attachments', []);
      const error = { attachments: 'Visible To is required.' };
      if (!isEmpty(attachments)) {
        map(attachments, object => {
          if (isEmpty(object.visible_to || !has(object, 'visible_to'))) {
            throw error;
          }
        });
      }
    },
    validate: fields =>
      validate(fields, {
        payment_profile_amount: (
          value,
          attributes,
          attributeName,
          options,
          constraints
        ) => {
          if (attributes.status === 'non_factored' || attributes.useNewNFRequest) {
            return null;
          }

          return {
            presence: true,
            numericality: {
              greaterThan: 1
            }
          };
        },

        //This validation was supposed be behind a toggle for the client created line items.
        // amount: (value, options) => {
        //   return {
        //     presence: true,
        //     numericality: {
        //       greaterThanOrEqualTo: options.payment_profile_amount,
        //       notGreaterThanOrEqualTo: `must be greater or equal to ${formatPennies(options.payment_profile_amount)}`,
        //     }
        //   }
        // },

        carrier_rate: {
          presence: true
        },
        debtor: (value, options, key, attributes, globalOptions) => {
          let errors = {};
          if (options.funding_request_type !== 'SELF_FINANCE_NO_INVOICE') {
            errors = {
              isNoDebtor: {
                funding_request_type: options.funding_request_type
              }
            }
          }
          if (options.funding_request_type === 'SELF_FINANCE_NO_INVOICE' && options.enableHideDebtor) {
            return;
          }
          return {
            presence: true,
            ...errors,
          }
        },
        carrier: (value, options, key, attributes, globalOptions) => {
          if (options.allowFrWithoutCarrier) {
            return;
          }
          return {
            presence: true
          }
        },
        status: {
          presence: true
        },
        line_items: (value, options, key, attributes, globalOptions) => {
          if (options.allowLineItemizedItems) {
            return {
              array: {
                amount: {
                  presence: true,
                  numericality: {
                    greaterThan: 0,
                  }
                },
                category: {
                  presence: true,
                }
              }
            }
          }
          return;
        },
      })
  })
)(
  ({
    handleSubmit,
    item = [],
    fetchInvoice,
    fetchCollated,
    disableAll,
    attachmentToCrop,
    factoring_id,
    deleteFactoringPayment,
    deleteAttachment,
    setAttachmentToCrop,
    handleAttachmentCropResult,
    formattedItems,
    factoring_company_name,
    disableLoadNumber,
    disablePurchaseOrderNumber,
    dispatch,
    formValues = {},
    factoring,
    isSubmittingCreateFuelAdvanceForm,
    onSubmitCreateFuelAdvance,
    status,
    invoiceSentCount,
    sendInvoice,
    canSendInvoice,
    change,
    loading,
    setLoading,
    isTmsUser,
    carrier,
    hasFactoring,
    invoiceCount,
    carrier_mc,
    carrier_email,
    carrier_phone,
    enableCarrierUpdate,
    refresh,
    setRefresh,
    disableDeleteAll,
    enableReserveHoldbackHybrid,
    newAttachments,
    self,
    uploadRef = {},
    setUploadRef,
    setDeletedAttachments,
    deletedAttachments,
    updatedStatus,
    selfFinanceRequested,
    factoringCreateNonFactoredFr,
    enabledContractTypes,
    allowSelfFinanceFr,
    allowSelfFinanceFrDeclinedDebtors,
    allowSelfFinanceFrApprovedDebtors,
    enableHideDebtor,
    formWarning,
    openLineItemsModal,
    getCalculation,
    updatedFormValues,
    allowLineItemizedItems,
    disableCarrierNotes,
    disableUpdateStatus,
    useNewNFRequest,
    ...props
  }) => {
    const isFuelAdvance = get(factoring, 'fuel_advance', false);
    const isFuelAdvanceStatus =
      updatedStatus === 'fuel_advance' ||
      get(formValues, 'fuel_advance_amount', undefined);
    const isNonFactored = updatedStatus === 'non_factored';
    const isNonFactoredCarrier = updatedStatus === 'non_factored_carrier';
    const disableOnNewNFRequest = ['NON_FACTORED_STANDARD', 'NON_FACTORED_BILL_OUT'].includes(formValues.funding_request_type) && useNewNFRequest;
    const id = get(props, 'params.id');
    return (
      <div className="container" style={{ paddingTop: 10, paddingBottom: 10 }}>
        {loading && <BackgroundSpinner />}
        <form onSubmit={handleSubmit} id="factoring-payment-edit-form">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {
                  (allowSelfFinanceFr || useNewNFRequest) &&
                  <FundingRequestType
                    disabled={disableAll}
                    change={change}
                    canCreateNonFactoredFr={factoringCreateNonFactoredFr}
                    canCreateSelfFinanceFr={allowSelfFinanceFr}
                    enabledContractTypes={enabledContractTypes}
                    useNewNFRequest={useNewNFRequest}
                    status={status}
                  />
              }
              <Field
                name="amount"
                label={<div><span>Shipper Invoice Amnt.</span> {(allowLineItemizedItems && !disableAll)  && <a href={void (0)} onClick={() => openLineItemsModal()}>(Edit Funding Request)<FontAwesomeIcon icon={faPen} size="sm" /></a>}</div>}
                component={PennyInput}
                disabled={allowLineItemizedItems || disableAll}
                onBlur={async (preventDefault, newValue, oldValue) => {
                  if (newValue !== oldValue) {
                    await getCalculation({ ...formValues, amount: newValue });
                  }
                }}
              />
              {hasFactoring ||
                !isFuelAdvance ||
                status === 'fuel_advance' ||
                ![
                  'incomplete',
                  'pending',
                  'special_pending',
                  'document_issue',
                  'pending_delivery'
                ].includes(status) ? (
                <Field
                  name="payment_profile_amount"
                  label="Carrier Invoice Amnt."
                  component={PennyInput}
                  disabled={disableAll}
                  onBlur={async (preventDefault, newValue, oldValue) => {
                    if (newValue !== oldValue) {
                      await getCalculation({
                        ...formValues,
                        payment_profile_amount: newValue
                      });
                    }
                  }}
                />
              ) : (
                <div className="row" style={{ display: 'flex' }}>
                  <div
                    className={isFuelAdvanceStatus ? 'col-xs-12' : 'col-xs-8'}
                  >
                    <Field
                      name="payment_profile_amount"
                      label="Carrier Invoice Amnt."
                      component={PennyInput}
                      disabled={disableAll}
                      onBlur={async (preventDefault, newValue, oldValue) => {
                        if (newValue !== oldValue) {
                          await getCalculation({
                            ...formValues,
                            payment_profile_amount: newValue
                          });
                        }
                      }}
                    />
                  </div>
                  {!isFuelAdvanceStatus && (
                    <div
                      className="col-xs-4"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignContent: 'center',
                        alignItems: 'center',
                        paddingTop: 10
                      }}
                    >
                      <Button
                        className="btn btn-blue"
                        onClick={() =>
                          dispatch(
                            openModal('EmptyModal', {
                              children: (
                                <CreateFuelAdvanceForm
                                  initialValues={formValues}
                                  factoring={factoring}
                                  onSubmit={onSubmitCreateFuelAdvance}
                                />
                              ),
                              title: 'Create Fuel Advance',
                              control: (
                                <button
                                  className="btn btn-orange"
                                  onClick={() =>
                                    dispatch(submit(FORM_CREATE_FUEL_ADVANCE))
                                  }
                                  disabled={isSubmittingCreateFuelAdvanceForm}
                                >
                                  Create
                                </button>
                              )
                            })
                          )
                        }
                      >
                        Create Fuel Advance
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {isFuelAdvance && (
                <div>
                  <Field
                    name="fuel_advance_amount"
                    label="Fuel Advance Amnt."
                    component={PennyInput}
                    disabled={true}
                  />
                  {!isEmpty(carrier) && (
                    <Field
                      name="paymentProfileAccount"
                      label={<div><span>Fuel Advance Payment Method</span>{hasFactoring ? <span className="text-red" style={{ paddingLeft: 5 }}>Factor Receiving Funds</span> : ""}</div>}
                      component={LabeledInput}
                      disabled={true}
                    />
                  )}
                </div>
              )}
              {!formValues.self_finance_requested && (
                <>
                  <Field
                    name="Carrier_fee"
                    label="Carrier Fee"
                    disabled={true}
                    component={PennyInput}
                  />
                  <Field
                    name="factoring_discount_amount"
                    label="Your Fee"
                    disabled={true}
                    component={PennyInput}
                  />
                  <Field
                    name="payment_profile_purchase_amount"
                    label="Carrier Pay "
                    disabled={true}
                    component={PennyInput}
                  />
                  <Field
                    name="factoring_unpaid_purchase_amount"
                    label="Broker Pay"
                    disabled={true}
                    component={PennyInput}
                  />
                  <Field
                    name="factoring_amount"
                    label="Broker Gross Margin"
                    disabled={true}
                    component={PennyInput}
                  />
                </>
              )
              }
              {(!isNonFactored && !selfFinanceRequested) && (
                <Field
                  name="reserve_holdback"
                  label="Reserve Holdback"
                  disabled={true}
                  component={PennyInput}
                />
              )}
              <Field
                name="invoice_number"
                label="Invoice #"
                disabled={true}
                component={LabeledInput}
              />
              <Field
                name="user_load_number"
                label="Load #"
                disabled={disableLoadNumber || disableAll}
                component={LabeledInput}
              />
              <Field
                disabled={disableCarrierNotes}
                name="carrier_pro_number"
                component={LabeledInput}
                label={<div><span>Carrier Pro #</span><span className="text-success" style={{ paddingLeft: 5 }}>(Visible to Carrier)</span></div>}
              />
              <Field
                name="purchase_order_number"
                component={LabeledInput}
                disabled={disablePurchaseOrderNumber || disableAll}
                label={<div><span>PO #</span><span className="text-success" style={{ paddingLeft: 5 }}>(Visible to Debtor)</span></div>}
              />
              {formWarning?.purchase_order_number && (
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between', }}>
                  {formWarning?.purchase_order_number}
                </div>
              )}
              <Field
                name="user_notes"
                disabled={disableCarrierNotes}
                component={LabeledInput}
                label={<div><span>Internal Funding Request Notes</span><span className="text-success" style={{ paddingLeft: 5 }}>(Visible to Comfreight Operations Team)</span></div>}
              />
              <Field
                name="user_reference_number"
                disabled={disableCarrierNotes}
                component={LabeledInput}
                label={<div><span>Client Invoice #</span><span className="text-success" style={{ paddingLeft: 5 }}>(Visible to Shipper and Carrier)</span></div>}
              />
              <Field
                name="carrier_invoice_notes"
                disabled={disableCarrierNotes}
                component={LabeledInput}
                label={<div><span>Carrier Invoice Details</span><span className="text-success" style={{ paddingLeft: 5 }}>(Visible to Carrier)</span></div>}
              />
              <Field
                name="debtor_invoice_notes"
                disabled={disableCarrierNotes}
                component={LabeledInput}
                label={<div><span>Shipper/Debtor Invoice Details</span><span className="text-success" style={{ paddingLeft: 5 }}>(Visible to Shipper)</span></div>}
              />
              <Field
                name="first_origin_location"
                label="First Pick up"
                inputProps={{
                  disabled: disableAll || isFuelAdvanceStatus
                }}
                component={GoogleLocationInput}
                specificity={GoogleLocationInput.specificity.ANY}
              />
              <Field
                name="final_destination_location"
                label="Final Delivery "
                inputProps={{
                  disabled: disableAll || isFuelAdvanceStatus
                }}
                component={GoogleLocationInput}
              />
              <Field
                name="load_length"
                label="Load Length"
                disabled={true}
                component={LabeledInput}
              />
              <Field
                name="load_trailer_type"
                label="Equipment Type"
                disabled={true}
                component={EquipmentType}
              />
              <div style={{ margin: 10 }}>
                {
                  formValues.self_finance_requested &&
                    <SelfFinanceFee
                      carrier_line_haul={formValues.carrier_line_haul}
                      payment_profile_discount_rate={formValues.payment_profile_discount_rate}
                      qp_total_fee={formValues.qp_total_fee}
                      carrier_qp_fee={formValues.carrier_qp_fee}
                      carrier_total_amount={formValues.carrier_total_amount}
                      broker_carrier_advance={formValues.broker_carrier_advance}
                      broker_self_finance_fee={formValues.broker_self_finance_fee}
                      broker_qp_profit={formValues.broker_qp_profit}
                      self_finance_total_amount={formValues.self_finance_total_amount}
                      factoring_fee_split_rate={formValues.factoring_fee_split_rate}
                      discount_rate_comfreight={formValues.discount_rate_comfreight}
                      self_finance_fee_rate_info={formValues.self_finance_fee_rate_info}
                    />
                }
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                name="created"
                label="Created At"
                disabled={true}
                component={LabeledInput}
              />
              <Field
                name="scheduled_purchase_date"
                label="Carrier Expected Processing At"
                disabled={true}
                component={LabeledInput}
              />
              <Field
                name="carrier_funded_at"
                label="Carrier Funded At"
                disabled={true}
                component={LabeledInput}
              />
              <Field
                name="payment_profile_amount_funded"
                label="Carrier Amount Funded"
                disabled={true}
                component={PennyInput}
              />
              <Field
                name="broker_funded_at"
                label="Broker Funded At"
                disabled={true}
                component={LabeledInput}
              />
              <Field
                name="carrier_rate"
                label="Carrier Rate "
                format={value => !isUndefined(value) && `${value}%`}
                parse={value => !isUndefined(value) && value.match(/\b\d+(?:.\d+)?/)}
                disabled={true}
                component={LabeledInput}
              />
              {isNonFactored ? (
                <Field
                  label={
                    <span>
                      Broker's % of Carrier QP Fee
                      <span style={styles.textStyle}>
                        {' '}
                        (Carrier Rate will change depending on the portion % you
                        absorb)
                      </span>
                    </span>
                  }
                  name="factoring_fee_split_rate"
                  disabled={true}
                  component={LabeledInput}
                />
              ) : (
                <RateField
                  onValueChange={async event =>
                    await getCalculation({
                      ...formValues,
                      factoring_fee_split_rate: event
                    })
                  }
                  name="factoring_fee_split_rate"
                  disabled={
                    disableAll ||
                    Boolean(factoring_company_name) ||
                    isFuelAdvanceStatus ||
                    disableOnNewNFRequest
                  }
                  label={
                    <span>
                      Broker's % of Carrier QP Fee
                      <span style={styles.textStyle}>
                        {' '}
                        (Carrier Rate will change depending on the portion % you
                        absorb)
                      </span>
                    </span>
                  }
                />
              )}
              <Field
                name="payout_days"
                component={LabeledDropdown}
                data={formattedItems}
                label="Carrier Payment Speed"
                textField="label"
                valueFiled="value"
                disabled={disableOnNewNFRequest || disableAll}
                onValueChange={async event => {
                  const factoringFeeSplitRate = (
                    find(formattedItems, ['value', event]) || {}
                  ).splitFee;
                  change('factoring_fee_split_rate', factoringFeeSplitRate);
                  change('payout_days', event);
                  await getCalculation({
                    ...formValues,
                    payout_days: event,
                    factoring_fee_split_rate: factoringFeeSplitRate
                  });
                }}
              />
              {
                !(formValues.funding_request_type === 'SELF_FINANCE_NO_INVOICE' && enableHideDebtor) &&
                <div>
                    <Field
                      label="Shipper/Debtor"
                      name="debtor"
                      component={DebtorAutocompleteInput}
                      placeholder=""
                      onChange={debtor => {
                        change("bill_to_company_phone", debtor.phone_number || '');
                        change("bill_to_company_email", debtor.email || '');
                      }}
                      disabled={disableAll || isFuelAdvanceStatus}
                      extraInfo={[
                        {
                          extraInfo: (
                            <a style={{ zIndex: 999 }}>
                              You don’t see the right debtor? Click here to add new
                              connection!{' '}
                            </a>
                          ),
                          action: () =>
                            window.open('/haul-pay/profile/creditcheck', '_blank')
                        }
                      ]}
                      connected={true}
                      noDebtor={formValues.funding_request_type === 'SELF_FINANCE_NO_INVOICE'}
                      filter={{
                        ...(formValues.self_finance_requested && {
                          declined: allowSelfFinanceFrDeclinedDebtors,
                          approved: allowSelfFinanceFrApprovedDebtors,
                          sf_no_invoice: !!(formValues.funding_request_type === 'SELF_FINANCE_NO_INVOICE')
                        })
                      }}
                    />
                  <Field
                    name="bill_to_company_phone"
                    label="Shipper/Debtor Phone"
                    disabled={true}
                    component={LabeledInput}
                  />
                  <Field
                    name="bill_to_company_email"
                    label="Shipper/Debtor Email"
                    disabled={true}
                    component={LabeledInput}
                  />
                </div>
              }
              <Field
                name="carrier"
                onChange={async carrier => {
                  try {
                    change("payout_days", carrier.payout_days);
                    await getCalculation({
                      ...formValues,
                      payout_days: carrier.payout_days,
                    });
                  } catch (e) {

                  }
                }}
                label={
                  <p>
                    Carrier{' '}
                    <Tooltip
                      text={
                        <p style={{ fontSize: 15 }}>
                          If wrong carrier assigned and no longer able to change, please decline this request and create a new one.
                        </p>
                      }
                    >
                      <MaterialIcon
                        name="error_outline"
                        size={20}
                        style={{ color: '#FF100E' }}
                      />
                    </Tooltip>
                  </p>
                }
                disabled={disableAll}
                component={CarrierNameAutocompleteInput}
                placeholder=""
                status="approved,needs_review,pending,declined"
              />
              <LabeledInput
                value={carrier_email}
                label="Carrier Email"
                disabled={true}
              />
              <LabeledInput
                value={carrier_mc}
                label="Carrier MC"
                disabled={true}
              />
              <LabeledInput
                value={carrier_phone}
                label="Carrier Phone"
                disabled={true}
              />
              {!isEmpty(carrier) && (
                <Field
                  name="paymentProfileAccount"
                  label={<div><span>Carrier Payment Method</span>{hasFactoring ? <span className="text-red" style={{ paddingLeft: 5 }}>Factor Receiving Funds</span> : ""}</div>}
                  disabled={true}
                  component={LabeledInput}
                />
              )}
              <StatusField
                name="status"
                useNewNFRequest={useNewNFRequest}
                isFuelAdvanceStatus={isFuelAdvanceStatus}
                selfFinanceRequested={formValues.self_finance_requested}
                status={status}
                updatedStatus={updatedStatus}
                label="Funding Request Status"
                disableAll={disableCarrierNotes || disableUpdateStatus}
              />
              <Field
                name="status_note"
                label="Status Note"
                component={LabeledInput}
                disabled={disableAll || isFuelAdvanceStatus}
              />
              {!isNonFactored && !isNonFactoredCarrier && factoring.enable_reserve_holdback_hybrid && !isUndefined(formValues.apply_reserve_holdback) ?
                <Field
                  toggled={formValues.apply_reserve_holdback}
                  disabled={formValues.self_finance_requested || disableOnNewNFRequest || disableAll }
                  name='apply_reserve_holdback'
                  component={Toggle}
                  style={{ width: 'auto !important' }}
                  label='Create w/Margin Advance'
                  labelStyle={{ width: 'auto !important' }}
                  onToggle={async (value, isInputChecked) => {
                    change('apply_reserve_holdback', isInputChecked);
                    await getCalculation({
                      ...formValues,
                      apply_reserve_holdback: isInputChecked
                    });
                  }}
                /> : <div />
              }
              <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div
                  className="col-xs-12"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 20
                  }}
                >
                  <div style={{ flex: 0.5 }}>
                    {canSendInvoice && (
                      <Button
                        className="btn btn-default btn-darkblue"
                        onClick={sendInvoice}
                      >
                        {' '}
                        Send Invoice Now
                      </Button>
                    )}
                  </div>
                  <div style={{ flex: 0.5, justifyContent: 'center' }}>
                    <UploadAttachments dispatch={dispatch} id={id} />
                  </div>
                </div>
                {!isEmpty(attachmentToCrop) && (
                  <ImageCropper
                    filename={attachmentToCrop.filename}
                    file={attachmentToCrop.download_url}
                    onCrop={handleAttachmentCropResult}
                    onCancel={file => {
                      setAttachmentToCrop({});
                      const attachments = remove(deletedAttachments, item => item.id === file.id);
                      setDeletedAttachments(attachments);
                    }}
                  />
                )}
                <Attachments
                  item={item}
                  attachments={newAttachments}
                  fetchInvoice={() => fetchInvoice(factoring_id)}
                  fetchCollated={() => fetchCollated(factoring_id)}
                  fetchCarrierInvoice={() =>
                    fetchInvoice(factoring_id, {
                      document: 'invoice_payment_profile'
                    })
                  }
                  onDeleteClick={attachment => {
                    deleteAttachment(factoring_id, attachment.id);
                  }}
                  onEditClick={object => {
                    setAttachmentToCrop(object);
                    if (isEmpty(find(deletedAttachments, ["id", object.id]))) {
                      deletedAttachments.push(object);
                    }
                  }}
                  invoiceSentCount={invoiceCount}
                  refreshDocs={refresh}
                  setRefreshDocs={setRefresh}
                  deletedAttachments={deletedAttachments}
                />
                <div className="text-right">
                  <Field
                    name="cropped_attachments"
                    disabled={disableAll}
                    component={MultiImageInput}
                    noButton={true}
                    multiple={true}
                    broker={true}
                    categories={[
                      'Signed Bill of Lading (Delivered)',
                      'Signed Bill of Lading (Pick-Up)',
                      'Rate Confirmation',
                      'Truck Order Not Used',
                      'Invoice',
                      'Freight Bill',
                      'Fuel Advance',
                      'Lumper Receipt',
                      'Detention Receipt',
                      'Late Fee Receipt',
                      'Other'
                    ]}
                    onCancel={file => {
                      const attachments = remove(deletedAttachments, item => item.id === file.id);
                      setDeletedAttachments(attachments);
                    }}
                  />
                </div>
                {(!isEmpty(formValues.debtor) && !isEmpty(formValues.carrier)) &&
                    <CoBrokeringWarning
                        coBrokerAllowed={get(formValues, 'debtor.co_broker_allowed')}
                        coBrokeringApproval={get(formValues, 'carrier.co_brokering_approval')}
                        brokerAuthority={get(formValues, 'carrier.authority')}
                    />
                }
              </div>
            </div>
            <div
              className="col-xs-12"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: 10
              }}
            >
              {!disableDeleteAll &&
                <Button
                  style={{ minWidth: '7em', borderRadius: 5 }}
                  className="btn btn-danger"
                  onClick={() => deleteFactoringPayment(factoring_id)}
                >
                  Delete
                </Button>
              }
              <div style={{ padding: 10 }} />
              <Button
                type="submit"
                style={{ minWidth: '7em' }}
                className="btn btn-orange"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
);
export default FactoringPaymentEditFormBroker;
