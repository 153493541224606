/* global EMPTY_PLACEHOLDER */

import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import mapProps from 'recompose/mapProps';
import { get, values } from 'lodash';
import getSelf from 'selectors/getSelf';
import { fetchCollated, fetchInvoice } from 'actions/resource/factoringpayment';
import { openModal } from 'actions/ui';
import formatPennies from 'helpers/formatPennies';
import createTableItems from 'helpers/createTableItems';

import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';
import Header from 'components/pure/Header';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import FactoringPaymentsTotalRevenue from '../FactoringPayments/FactoringPaymentsTotalRevenue';
import FactoringPaymentAttachments from 'components/pure/FactoringPaymentAttachments';
import Spinner from 'components/pure/Spinner';
import selectBrokerGroups from 'selectors/selectBrokerGroups';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import groups from 'actions/admin/factoring/groups';
import groupUser from 'actions/admin/factoringGroupUsers';
import PaymentSpeedDropDown from '../../pure/form/inputs/PaymentSpeedDropDown';
import TRANSACTION_STATUS from '../../../helpers/TRANSACTION_STATUS';
import { FUNDING_REQUEST_CONTRACT_TYPES } from '../../../helpers';

const PaymentsTableComponents = createTableItems(
  ({ data, fetchInvoice, fetchCollated }, is_mobile) => {
    const company_name = get(data, ['debtor', 'dba']) || get(data, 'bill_to_company_name');

    return [
      company_name,
      formatPennies(data.amount),
      data.invoice_number,
      data.user_load_number || EMPTY_PLACEHOLDER,
      data.created.format('l LTS'),
      data.funded_at ? data.funded_at.format('l LTS') : EMPTY_PLACEHOLDER,
      (data.discount_rate || data.discount_rate === 0) ? `${data.discount_rate}%` : EMPTY_PLACEHOLDER,
      formatPennies(data.unpaid_purchase_amount) || EMPTY_PLACEHOLDER,
      <b
        className={cs('text-uppercase', {
          'text-success': ['approved', 'paid'].includes(data.status),
          'text-warning': ['special_Pending', 'pending'].includes(data.status),
          'text-red': ['declined', 'incomplete', 'pending_delivery'].includes(data.status),
        })}
        >
        {data.status === USER_FACTORING_STATUS.PENDING_DELIVERY ? 'incomplete' : data.status}
      </b>,
      [FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_BILL_OUT, FUNDING_REQUEST_CONTRACT_TYPES.NON_FACTORED_STANDARD].includes(data.contract_type)  ? 
        data.status !== TRANSACTION_STATUS.PAID ? <p>Contact Broker for Payment Details</p>
        : ''
      : <PaymentSpeedDropDown
        disabled
        brokerFactoringId={data.factoring}
        value={data.payout_days}
        paymentRequestId={data.id}
      />,
      is_mobile ?
        <div className='text-right'>
          <FactoringPaymentAttachments
            attachments={data.attachments}
            fetchInvoice={() => fetchInvoice(data.id, { document: 'invoice_payment_profile' })}
          />
        </div>
        : null,
      <Link to={`/haul-pay/getpaid/${data.id}/assigned-loads`}>
        <button className='btn btn-orange' type='button'>More</button>
      </Link>,
    ];
  },
  connect(
    null,
    dispatch => ({
      fetchInvoice(...args) {
        try {
          return dispatch(fetchInvoice(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch invoice at this time. Please try again later.' }));
        }
      },
      fetchCollated(...args) {
        try {
          return dispatch(fetchCollated(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch pdf at this time. Please try again later.' }));
        }
      },
    }),
  )
);

const PaymentsTableClient = compose(
  connect(
    state => {
      return {
        id: state.user.id,
      };
    },
    {},
  ),
  mapProps(({ id, debtor }) => ({
    id,
    fetchArgs: {
      assigned_loads: true,
    },
  })),
  ResourceBy('user', 'factoringpayment', {
    shouldClearBeforeFetch: true,
  }),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringpayment',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
)(({ actions, item, items }) => (
  <div>
    <ResponsiveTable
      items={items}
      isFetching={item.isFetching}
      fetchNext={actions.fetchNext}
      placeholder='No payments.'
      headers={['Bill To', 'Invoice Amnt.', 'Invoice #', 'Load #', 'Requested At', 'Funded At', 'Rate', 'Payment Amnt.', 'Status', 'Payment Speed', '', 'Details']}
      TableItem={PaymentsTableComponents.TableItem}
      BlockTableItem={PaymentsTableComponents.BlockTableItem}
      striped={true}
      desktopContainerProps={{
        style: {
          overflow: 'visible',
        },
      }}
    />
    {
      item.next ?
        <div>
          <button type='button' className='btn btn-orange' onClick={actions.fetchNext} disabled={item.isFetching}>Load More</button>
        </div>
        : null
    }
  </div>
));

const FactoringPayments = compose(
  connect(
    state => {
      const factoring_discount_rate = getSelf(state).data.factoring_discount_rate;
      return {
        factoring_discount_rate,
        groups: state.resource.factoringGroups,
        brokerGroups: selectBrokerGroups(state),
        loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
      };
    },
    dispatch => ({
      getGroups: () => dispatch(groups.fetch()),
      getGroupsUsers: id => dispatch(groupUser.fetch({ id })),
    }),
  ),
)(class FactoringPayments extends Component {
  async componentDidMount() {
    const groups = await this.props.getGroups();

    const brokerGroup = get(groups, ['payload', 'results'], [])
      .filter(group => (group.type === 'haulpay_broker'));
  }

  render() {
    const { factoring_discount_rate, params: { id } } = this.props;

    const { loading } = this.props;

    return (
      <div className='container'>
        <div className='row'>
          <div className='clearfix' style={{ margin: '12.5px 0' }}>
            <Header style={{ display: 'inline-block', marginTop: 0 }}>Assigned Loads</Header>
          </div>
          {loading ? <Spinner /> : null }
          {!loading && <PaymentsTableClient debtor={id} />}
        </div>
      </div>
    );
  }
});

export default FactoringPayments;
