import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { lifecycle, shallowEqual, withStateHandlers } from 'recompose';
import { Field, formValueSelector, reduxForm, change, FieldArray } from 'redux-form';
import formatPennies from 'helpers/formatPennies';
import ModalBody from 'components/pure/ModalBody';
import Header from 'components/pure/Header';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import DebtorAutocompleteInput from 'components/pure/form/inputs/DebtorAutocompleteInput';
import CarrierNameAutocompleteInput from 'components/pure/form/inputs/CarrierNameAutocompleteInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import Toggle from 'material-ui/Toggle';
import validate from 'validate.js';
import { get, find, isEmpty, delay, isUndefined, flow, map, compact, concat, reduce } from 'lodash';
import { formattedData } from 'components/pure/form/inputs/PaymentSpeedDropDown';
import getBrokerTerms from '../../../selectors/getBrokerTerms';
import { fetchTerms } from 'actions/factoring';
import { closeModal } from 'actions/ui';
import { fetchFactoringProfile } from 'actions/user';
import { fundingRequestCalculations, PurchaseOrderNumberRelatedInvoices } from '../../../actions/factoring';
import Spinner from '../Spinner';
import isTmsUser from '../../../selectors/isTmsUser';
import { DebtorAutocompleteItem } from './inputs/DebtorAutocompleteInput';
import FundingRequestInvoices from '../FundingRequestInvoices';
import LineItemsForm from './LineItemsForm';
import { NO_DEBTOR_ID } from '../../../helpers';

export const FORM_NAME = 'DebtorCarrierPayment';
const styles = {
  labelText: {
    color: '#498504',
    fontSize: 10,
  },
  feeStyleBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textStyle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#000000',
  },
  description: {
    fontSize: 16,
  },
  flexCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
};
const PaymentMethod = ({ paymentMethods }) => {
  const data = [{
    text: 'ACH',
    fee: ' - 1 day (Free)',
    value: 'ach',
  }];

  const ListItem = ({ item: { text, fee } }) => (
    <div>{text}<span className='text-danger'>{fee}</span></div>
  );

  return (
    <Field
      name='payment_method'
      component={LabeledDropdown}
      label='Payment Method'
      data={isEmpty(paymentMethods) ? data : paymentMethods}
      disabled={!paymentMethods.length}
      itemComponent={ListItem}
    />
  );
};

const BrokerRate = props => {
  const data = [{
    text: '0%',
    value: 0,
  },
  {
    text: '50%',
    value: 50,
  },
  {
    text: '100%',
    value: 100,
  }];

  const ListItem = ({ item: { text, fee } }) => (
    <div>{text}</div>
  );

  return (
    <Field
      name='broker_rate'
      component={LabeledDropdown}
      label='Broker’s % of Carrier QP Fee '
      data={data}
      itemComponent={ListItem}
      {...props}
    />
  );
};

const FundingRequestType = props => {
  if(props.useNewNFRequest) {
      props.enabledContractTypes['NON_FACTORED_BILL_OUT'] = true;
      props.enabledContractTypes['NON_FACTORED_STANDARD'] = true;
  }
  const disabledValue = reduce(props.enabledContractTypes, (results, value, key) => !value ? concat(results, [key]) : results, []);

  const data = [{
    text: 'Factored',
    value: 'STD_BROKER',
    type: 'Standard Funding Requests'
  }, {
    text: 'Non-Factored Pay Carrier',
    value: 'NON_FACTORED_STANDARD',
    type: 'Non Factored Funding Requests'
  }, {
    text: 'Non-Factored Bill Out Only',
    value: 'NON_FACTORED_BILL_OUT',
    type: 'Non Factored Funding Requests'
  }, {
    text: 'Self-Financed No Invoicing',
    value: 'SELF_FINANCE_NO_INVOICE',
    type: 'Non Factored Funding Requests'
  }, {
    text: 'Self-Financed With Invoicing',
    value: 'SELF_FINANCE_WITH_INVOICE',
    type: 'Non Factored Funding Requests'
  }];

  return (
    <Field
      disabled={disabledValue}
      name='funding_request_type'
      component={LabeledDropdown}
      label='Funding Request Type'
      data={data}
      groupBy={FRType => FRType.type}
      onValueChange={value => {
        switch (value) {
          case 'NON_FACTORED_STANDARD':
            props.change('non_factored', true);
            props.change('bill_out_only', false);
            props.change('self_finance_requested', false);
            props.change('contract_type', 'NON_FACTORED_STANDARD');
            break;
          case 'NON_FACTORED_BILL_OUT':
            props.change('non_factored', true);
            props.change('bill_out_only', true);
            props.change('self_finance_requested', false);
            props.change('contract_type', 'NON_FACTORED_BILL_OUT');
            break;
          case 'SELF_FINANCE_NO_INVOICE':
            props.change('non_factored', false);
            props.change('bill_out_only', false);
            props.change('self_finance_requested', true);
            props.change('apply_reserve_holdback', false);
            props.change('contract_type', 'SELF_FINANCE_NO_INVOICE');
            break;
          case 'SELF_FINANCE_WITH_INVOICE':
            props.change('non_factored', false);
            props.change('bill_out_only', false);
            props.change('self_finance_requested', true);
            props.change('apply_reserve_holdback', false);
            props.change('contract_type', 'SELF_FINANCE_WITH_INVOICE');
            break;
          default:
            props.change('non_factored', false);
            props.change('bill_out_only', false);
            props.change('self_finance_requested', false);
            props.change('apply_reserve_holdback', false);
            props.change('contract_type', 'STD_BROKER');
            break;

        }

      }}
      {...props}
    />
  );
};

const DebtorCarrierPaymentForm = (({
  display,
  loading,
  holdBack,
  factoringCreateNonFactoredFr,
  enabledContractTypes,
  allowSelfFinanceFr,
  closeModal,
  nonFactored,
  paymentMethods,
  fee = 0,
  change,
  assignLoad = false,
  shipperOffset,
  amount,
  formattedItems,
  paymentSpeedSplitFee,
  additionRate,
  debtorDiscount,
  debtorSplit,
  displayAdditionalRate,
  hasFactoring,
  factoringCompanyPaymentTerm,
  reserve_amount,
  is_reserve_holdback,
  payment_profile_payable,
  total_comfreight_fee,
  factoring_fee,
  payment_profile_fee,
  factoring_payable,
  billOutOnly,
  setCarrierNotFound,
  carrierNotFound,
  enableReserveHoldbackHybrid,
  formValues,
  user,
  selfFinanceFrLimit,
  selfFinanceFrRemainingBalance,
  carrier_line_haul,
  payment_profile_discount_rate,
  qp_total_fee,
  carrier_qp_fee,
  carrier_total_amount,
  broker_carrier_advance,
  broker_self_finance_fee,
  broker_qp_profit,
  self_finance_total_amount,
  factoring_fee_split_rate,
  discount_rate_comfreight,
  allowSelfFinanceFrDeclinedDebtor,
  allowSelfFinanceFrApprovedDebtor,
  formWarning,
  openPo,
  poRelatedInvoice,
  allowLineItemizedItems,
  enableHideDebtor,
  self_finance_fee_rate_info,
  carrier,
  debtor,
  useNewNFRequest,
  ...props
}) =>
  <div>
    <ModalBody closeModal={closeModal}>
      <Header style={{ color: '#E86C00', marginBottom: '20' }}>{`Create a ${nonFactored ? 'Non-Factored Request' : 'Funding Request'}`}</Header>
      <div className='light-placeholder'>
        {
            (allowSelfFinanceFr || useNewNFRequest) ?
            <FundingRequestType
              change={change}
              canCreateNonFactoredFr={factoringCreateNonFactoredFr}
              canCreateSelfFinanceFr={allowSelfFinanceFr}
              enabledContractTypes={enabledContractTypes}
              useNewNFRequest={useNewNFRequest}
            /> : factoringCreateNonFactoredFr &&
            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <div id='non_factored'>
                  <Field
                    name='non_factored'
                    component={Toggle}
                    style={{ width: 'auto !important' }}
                    label={<p>Create Non-Factored</p>}
                    labelStyle={{ width: 'auto !important' }}
                    onToggle={(value, isInputChecked) => {
                      change('non_factored', isInputChecked);
                      change('contract_type', isInputChecked ? 'NON_FACTORED_STANDARD' : 'STD_BROKER');
                    }}
                  />
                </div>
              </div>
              {
                nonFactored &&
                <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                  <Field
                    name='bill_out_only'
                    component={Toggle}
                    style={{ width: 'auto !important' }}
                    label={<p>Bill Out Only</p>}
                    labelStyle={{ width: 'auto !important' }}
                    onToggle={(value, isInputChecked) => {
                      change('bill_out_only', isInputChecked);
                      if (isInputChecked) {
                        change('contract_type', 'NON_FACTORED_BILL_OUT');
                      }
                    }}
                  />
                </div>
              }
            </div>

        }
        <Field
          name='amount'
          component={PennyInput}
          label={
            <p>Shipper Invoice Amount
              <span style={styles.labelText}> (Carriers will not see this in their side)</span>
            </p>
          }
          placeholder=''
          isUpdating={true}
          disabled={allowLineItemizedItems}
        />
        {
          allowLineItemizedItems && <FieldArray name="line_items" component={LineItemsForm} />
        }
        {
          shipperOffset &&
          <span className='text-danger'>Please make sure your shipper is ok with the increase in their rate before proceeding.</span>
        }
        {(nonFactored && !billOutOnly) &&
          <span className='text-danger'>Warning: The carrier will be paid when you are paid when the shipper settles the invoice in the future. Do not pay the carrier from your side with this option selected.</span>
        }
        {!billOutOnly &&
          <Field
            name='carrier_amount'
            component={PennyInput}
            label={<p>Carrier Invoice Amount</p>}
            placeholder=''
          />
        }
        {billOutOnly &&
          <span className='text-danger'>You will be paid when the shipper pays their invoice. We will not pay the carrier with this option.</span>
        }
        {
          !(formValues.funding_request_type === 'SELF_FINANCE_NO_INVOICE' && enableHideDebtor) &&
            <Field
              label={<p>Shipper/Debtor<span style={styles.labelText}> (Carriers will not see this in their side)</span></p>}
              name='bill_to'
              component={DebtorAutocompleteInput}
              extraInfo={[{
                extraInfo: <div>
                  <a style={{ zIndex: 999 }}>You don’t see the right debtor? Click here to add new connection! </a>
                </div>,
                action: () => window.open('/haul-pay/profile/creditcheck', '_blank'),
              }]}
              connected={true}
              labelProps={{
                style: { display: 'block' },
              }}
              placeholder=''
              noDebtor={formValues.funding_request_type === 'SELF_FINANCE_NO_INVOICE'}
              filter={{
                ...(formValues.self_finance_requested && {
                  declined: allowSelfFinanceFrDeclinedDebtor,
                  approved: allowSelfFinanceFrApprovedDebtor,
                  sf_no_invoice: !!(formValues.funding_request_type === 'SELF_FINANCE_NO_INVOICE')
                })
              }}
            />
        }
        {
          formValues.self_finance_requested &&
          <p className='text-danger'>{`You've chosen to pay the carrier using your own funds. When self-financing QuickPay the limit for each funding request is ${formatPennies(selfFinanceFrLimit)}. You can Fund ${formatPennies(selfFinanceFrRemainingBalance)} more worth of funding requests.`}</p>
        }
        {!billOutOnly &&
          <Field
            label={<p>Carrier</p>}
            name='carrier'
            component={CarrierNameAutocompleteInput}
            labelProps={{
              style: { display: 'block' },
            }}
            status='approved,needs_review,pending,declined'
            placeholder=''
            onFocus={() => setCarrierNotFound(true)}
            onBlur={() => setCarrierNotFound(false)}
            onChange={val => {
              change('carrier', val);
              const changePayout = () =>
                change('payout_days', val.payout_days)
              const changeBrokerRate = () =>
                change('broker_rate', parseInt(get(val, 'factoring_fee_split_rate', 100)));
              const changePaymentMethod = () => {
                const factoring_company_method = get(val, 'factoring_company_relation.account.method', undefined);
                if (!factoring_company_method) {
                  change('payment_method', get(val, 'payment_profile.enable_pay_via_check', false) ? 'check' : 'ach');
                } else {
                  change('payment_method', get(val, 'factoring_company_relation.account.method', 'ACH').toLowerCase());
                }
              }
              delay(flow(changePayout, changeBrokerRate, changePaymentMethod), 500);
            }}
          />
        }
        {/*{*/}
        {/*  carrierNotFound &&*/}
        {/*  <span className='text-danger' style={{ paddingTop: 5, paddingBottom: 5 }}>If you don't see a carrier check My Carriers to see if they are approved.</span>*/}
        {/*}*/}
        <div style={{ display: 'flex', flexDirection: ' row', flex: 1, justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div style={{ flex: 0.33, marginRight: 5 }}>
            <Field
              label={<p>Load #</p>}
              disabled={assignLoad}
              name='user_load_number'
              component={LabeledInput}
              labelProps={{
                style: { display: 'block' },
              }}
              placeholder=''
            />
          </div>
          <div style={{ flex: 0.33, marginLeft: 5 }}>
            <Field
              name='carrier_pro_number'
              label={<p>Carrier Pro #</p>}
              component={LabeledInput}
              labelProps={{
                style: { display: 'block' },
              }}
            />
          </div>
          <div style={{ flex: 0.33, marginLeft: 5 }}>
            <Field
              name='purchase_order_number'
              label={<p>PO #</p>}
              component={LabeledInput}
              labelProps={{
                style: { display: 'block' },
              }}
            />
          </div>
        </div>
          {formWarning?.purchase_order_number && (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between', }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>{formWarning?.purchase_order_number}</div>
              {openPo &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <FundingRequestInvoices items={poRelatedInvoice} />
                </div>
              }
            </div>
          )}
        <PaymentMethod paymentMethods={paymentMethods} />
        {!nonFactored &&
          <div>
            <Field
              name='payout_days'
              component={LabeledDropdown}
              data={formattedItems}
              label='Carrier Payment Speed'
              textField='label'
              valueField='value'
              onObjectChange={val => !hasFactoring ? change('broker_rate', val.splitFee) : change('broker_rate', 100)}
            />
          </div>
        }
        {(!nonFactored) &&
          <BrokerRate disabled={hasFactoring} />
        }
        {
          (displayAdditionalRate && (!nonFactored || billOutOnly)) ?
            <div>
              <p className='text-danger'>Notice: This debtor has a debtor specific rate of {debtorDiscount}%</p>
              {
                debtorSplit ?
                  <p className='text-danger'><b> You have enabled passing on the additional debtor specific rate cost to the carrier. Please let the carrier know about the increase to their Quick Pay rate for this transaction.</b></p>
                  : <p className='text-danger'><b>The equivalent of {additionRate}% measured on the carrier's pay will be deducted from your margin.</b></p>
              }
            </div> : null
        }
        <div className='row'>
          <div className='col-xs-12'>
            <div className='col-xs-6' />
            {!nonFactored && !billOutOnly && enableReserveHoldbackHybrid ?
              <div className='col-xs-6' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Field
                  toggled={formValues.apply_reserve_holdback}
                  disabled={formValues.self_finance_requested}
                  name='apply_reserve_holdback'
                  component={Toggle}
                  style={{ width: 'auto !important' }}
                  label='Create w/Margin Advance'
                  labelStyle={{ width: 'auto !important' }}
                  onToggle={(value, isInputChecked) =>
                    change('apply_reserve_holdback', isInputChecked)
                  }
                />
              </div> : <div />
            }
          </div>
          {
            loading &&
            <div className='row'>
              <div className='col-xs-12' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Spinner style={{ fontSize: 10 }} />
              </div>
            </div>
          }
          {
            (display && !formValues.self_finance_requested) &&
              <NonSelfFinanceFee
                billOutOnly={billOutOnly}
                payment_profile_discount_rate={payment_profile_discount_rate}
                total_comfreight_fee={total_comfreight_fee}
                is_reserve_holdback={is_reserve_holdback}
                reserve_amount={reserve_amount}
                payment_profile_fee={payment_profile_fee}
                factoring_fee={factoring_fee}
                payment_profile_payable={payment_profile_payable}
                factoring_payable={factoring_payable}
                nonFactored={nonFactored}
              />
          }
          {
            (display && formValues.self_finance_requested) &&
            <SelfFinanceFee
              carrier_line_haul={carrier_line_haul}
              payment_profile_discount_rate={payment_profile_discount_rate}
              qp_total_fee={qp_total_fee}
              carrier_qp_fee={carrier_qp_fee}
              carrier_total_amount={carrier_total_amount}
              broker_carrier_advance={broker_carrier_advance}
              broker_self_finance_fee={broker_self_finance_fee}
              broker_qp_profit={broker_qp_profit}
              self_finance_total_amount={self_finance_total_amount}
              factoring_fee_split_rate={factoring_fee_split_rate}
              discount_rate_comfreight={discount_rate_comfreight}
              self_finance_fee_rate_info={self_finance_fee_rate_info}
            />
          }
        </div>
          {(!isEmpty(debtor) && !isEmpty(carrier)) &&
            <CoBrokeringWarning
                coBrokerAllowed={get(debtor, 'co_broker_allowed')}
                coBrokeringApproval={get(carrier, 'co_brokering_approval')}
                brokerAuthority={get(carrier, 'authority')}
            />
          }
      </div>
    </ModalBody>
  </div>
);

export const CoBrokeringWarning = ({ coBrokerAllowed, coBrokeringApproval, brokerAuthority }) => {
    if (brokerAuthority !== 'BROKER_AUTH') {
        return <div />
    }
    const isShipperApprovedNonApprovedCarrier = coBrokeringApproval !== 'APPROVED' && coBrokerAllowed === 'APPROVED';
    const isShipperNonApprovedApprovedCarrier = coBrokeringApproval === 'APPROVED' && coBrokerAllowed !== 'APPROVED';
    const isShipperNonApprovedNonApprovedCarrier = coBrokeringApproval !== 'APPROVED' && coBrokerAllowed !== 'APPROVED';

    if (isShipperNonApprovedApprovedCarrier) {
        return (
            <div style={styles.flexCol}>
                <p style={{ ...styles.description, fontWeight: 'bold' }}>
                    Warning - This Carrier is a broker a Co-Brokering Acknowledgment from your Shipper will need to be uploaded to your Debtors profile in the Credit section before you can pay the carrier or approve this funding request.
                </p>
                <p style={{ ...styles.description, fontWeight: 'bold' }} className="text-danger">
                    Two types of acknowledgment are acceptable.
                </p>
                <p style={{ ...styles.description }} className="text-danger">
                    Option #1 - A signed broker/shipper agreement that states you (the broker) has the
                        right  to utilize a co-brokering arrangement on freight you move for them.
                </p>
                <p style={{ ...styles.description }} className="text-danger">
                    Option #2 - An email from an authorized staff member (typically an AP manager)
                          stating you (the broker) have the right to utilize a co-brokering arrangement
                          on freight you move for them.
                </p>
            </div>
        )
    } else if (isShipperApprovedNonApprovedCarrier) {
        return (
            <div style={styles.flexCol}>
                <p style={{ ...styles.description, fontWeight: 'bold' }}>
                     Warning - This Carrier is a broker a signed Co-Brokering Agreement signed by both parties must be uploaded to the co-brokers invited carrier profile in your carriers section before you can pay the carrier or approve this funding request.
                </p>
            </div>
        )
    } else if (isShipperNonApprovedNonApprovedCarrier) {
        return (
            <div style={styles.flexCol}>
                <p style={{ ...styles.description, fontWeight: 'bold' }}>
                    Warning - This Carrier is a broker and 2 documents will be required for you to pay them on our platform as a
                    Co-Broker or approve this funding request.
                </p>
                <p style={{ ...styles.description, fontWeight: 'bold' }}>
                    #1 - A signed Co-Brokering Agreement signed by both parties must be uploaded to the co-brokers
                    invited carrier profile after you add them to your payment group.
                </p>
                <p style={{ ...styles.description, fontWeight: 'bold' }}>
                    #2 - A Co-Brokering Acknowledgment from your Shipper will need to be uploaded to your Debtors
                    profile in the Credit section.
                </p>
                <p style={{ ...styles.description, fontWeight: 'bold' }} className="text-danger">
                    Two types of acknowledgment are acceptable.
                </p>
                <p style={{ ...styles.description }} className="text-danger">
                    Option #1 - A signed broker/shipper agreement that states you (the broker) has the
                    right to utilize a co-brokering arrangement on freight you move for them.
                </p>
                <p style={{ ...styles.description }} className="text-danger">
                    Option #2 - An email from an authorized staff member (typically an AP manager)
                    stating you (the broker) have the right to utilize a co-brokering arrangement
                    on freight you move for them.
                </p>
            </div>
        )
    }
    return <div />
}

export const NonSelfFinanceFee = ({
                                      billOutOnly,
                                      payment_profile_discount_rate,
                                      total_comfreight_fee,
                                      is_reserve_holdback,
                                      reserve_amount,
                                      payment_profile_fee,
                                      factoring_fee,
                                      payment_profile_payable,
                                      factoring_payable,
                                      nonFactored
                                  }) => {
    return (
        <div>
            <div className="col-xs-12">
                <div className="col-xs-6">
                    {!billOutOnly &&
                        <p>Carrier's Rate: <span
                            className="text-danger"
                        >{payment_profile_discount_rate}%</span></p>
                    }
                </div>
                <div className="col-xs-6" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <p>Total Fee: <span className="text-danger">{formatPennies(total_comfreight_fee)}</span></p>
                </div>
            </div>
            {
                (is_reserve_holdback && !nonFactored) &&
                <div className="col-xs-12">
                    <div className="col-xs-6" />
                    <div className="col-xs-6" style={styles.feeStyleBox}>
                        <p style={styles.textStyle}>Reserve Holdback: <span
                            className="text-danger"
                        >{formatPennies(reserve_amount)}</span></p>
                    </div>
                </div>
            }
            <div className="col-xs-12">
                <div className="col-xs-6">
                    {!billOutOnly &&
                        <p style={styles.textStyle}>Your Carrier's Fee: <span
                            className="text-danger"
                        >{formatPennies(payment_profile_fee)}</span></p>
                    }
                </div>
                <div className="col-xs-6" style={styles.feeStyleBox}>
          <p style={styles.textStyle}>Your Fee: <span
            className='text-danger'
          >{formatPennies(factoring_fee)}</span></p>
        </div>
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-12' style={{ height: 1, backgroundColor: '#979797' }} />
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          {!billOutOnly &&
            <p style={styles.textStyle}>Your Carrier's Pay: <span
              className='text-success'
            >{formatPennies(payment_profile_payable)}</span></p>
          }
        </div>
        <div className='col-xs-6' style={styles.feeStyleBox}>
          <p style={styles.textStyle}>Your Pay: <span
            className='text-success'
          >{formatPennies(factoring_payable)}</span></p>
        </div>
      </div>
    </div>
  )
}
export const SelfFinanceFee = ({
  carrier_line_haul,
  payment_profile_discount_rate,
  qp_total_fee,
  carrier_qp_fee,
  carrier_total_amount,
  broker_carrier_advance,
  broker_self_finance_fee,
  broker_qp_profit,
  self_finance_total_amount,
  factoring_fee_split_rate,
  discount_rate_comfreight,
  self_finance_fee_rate_info,
}) => {
  const qpProfit = Math.abs(broker_qp_profit) - broker_self_finance_fee;
  return (
    <div>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          <p style={styles.textStyle}>Carrier</p>
        </div>
        <div className='col-xs-6' style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={styles.textStyle}>Broker</p>
        </div>
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          <span style={styles.textStyle}>Line haul: <span
            className='text-success'
          >{formatPennies(carrier_line_haul || 0)}</span></span>
        </div>
        <div className='col-xs-6'>
          <span style={styles.textStyle}>Line haul: <span
            className='text-danger'
          >{formatPennies(carrier_line_haul || 0)}</span></span>
        </div>
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          <span style={styles.textStyle}>{`QP Rate ${discount_rate_comfreight}%:`} <span
            className='text-danger'
          >{formatPennies(qp_total_fee || 0)}</span></span>
        </div>
        <div className='col-xs-6' >
          <span style={styles.textStyle}>Self finance fee: <span
            className='text-danger'
          >{formatPennies(broker_self_finance_fee || 0)}</span>
            <span style={styles.textStyle}>{` (${self_finance_fee_rate_info})`}</span>
          </span>
        </div>
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          <span style={styles.textStyle}>{`Carrier QP Fee ${100 - factoring_fee_split_rate}%: `}<span
            className='text-danger'
          >{formatPennies(Math.abs(carrier_qp_fee) || 0)}</span></span>
        </div>
        <div className='col-xs-6'>
          <span style={styles.textStyle}>Gross QP Profit: <span
            className='text-success'
          >{formatPennies(Math.abs(broker_qp_profit) || 0)}</span></span>
        </div>
      </div>
      <div className='col-xs-12' style={{ paddingTop: 10 }}>
        <div className='col-xs-12' style={{ height: 1, backgroundColor: '#979797' }} />
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          <span style={styles.textStyle}>Carrier Pay: <span
            className='text-success'
          >{formatPennies(carrier_total_amount || 0)}</span></span>
        </div>
        <div className='col-xs-6'>
          <span style={styles.textStyle}>Debit Amount: <span
            className='text-danger'
          >{formatPennies(self_finance_total_amount || 0)}</span></span>
        </div>
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-6' />
        <div className='col-xs-6'>
          <span style={styles.textStyle}>Net QP Profit: <span
            className={qpProfit >= 0 ? 'text-success' : 'text-danger'}
          >{formatPennies(qpProfit || 0)}</span></span>
        </div>
      </div>
    </div>
  )
}

const asyncValidate = async (values, dispatch, props ) => {
  if (!values.purchase_order_number) return;
  const results = await dispatch(PurchaseOrderNumberRelatedInvoices(values.purchase_order_number));
  if(!isEmpty(results)) {
    props.setFormWarning({ purchase_order_number: <p className='text-danger'>This PO# has been used on another invoice <a href={void(0)} onClick={props.setOpenPo}>Click Here</a> to view</p>});
    props.setPoRelatedInvoice(results);
  } else {
    props.setFormWarning({});
    props.setPoRelatedInvoice([]);
  }
  return;
}


const selectorForm = formValueSelector(FORM_NAME);
export default compose(
  connect(
    (state, ownProps) => {
      try {
        let factoringCompanyPaymentTerm = {
          discount_rate: 1,
          factoring_fee_split_rate: 100,
          payout_days: 'ach_30_day',
        };
        const holdBack = get(state, 'user.factoring.data.reserve_holdback', false);
        const allowNegativeMargin = get(state, 'user.factoring.data.allow_negative_margin', false);
        const negativeMarginValue = get(state, 'user.factoring.data.negative_margin_value', 0);
        const negativeMarginLimit = get(state, 'user.factoring.data.negative_margin_limit', 0);
        const allowFrWithoutCarrier = get(state, 'user.factoring.data.allow_fr_without_carrier', false);
        const selfFinanceFrRemainingBalance = get(state, 'user.factoring.data.self_finance_fr_remaining_balance', 0);
        const selfFinanceFrLimit = get(state, 'user.factoring.data.self_finance_fr_limit', 0);
        const factoringCreateNonFactoredFr = get(state, `resource.user[${state.user.id}].data.factoring_create_non_factored_fr`, false);
        const enabledContractTypes = get(state, `user.factoring.data.enabled_contract_types`, false);
        const allowSelfFinanceFr = get(state, `user.factoring.data.allow_self_finance_fr`, false);
        const allowSelfFinanceFrDeclinedDebtor = get(state, `user.factoring.data.allow_self_finance_fr_declined_debtors`, false);
        const allowSelfFinanceFrApprovedDebtor = get(state, `user.factoring.data.allow_self_finance_fr_approved_debtors`, false);
        const enableHideDebtor = get(state, `user.factoring.data.enable_hide_debtor`, false);
        const allowLineItemizedItems = get(state, `user.factoring.data.allow_line_itemized_items`, false);
        const defaultFrTypeApi = get(state, `user.factoring.data.default_fr_type_api`, '');
        const isSelfFinanceDefaultFR = ['SELF_FINANCE_NO_INVOICE', 'SELF_FINANCE_WITH_INVOICE'].includes(defaultFrTypeApi)
        const allowSelfFinanceApprovedDebtorsOutOfCredit = get(state, `user.factoring.data.allow_self_finance_approved_debtors_out_of_credit`, false);
        const enableReserveHoldbackHybrid = get(state, `user.factoring.data.enable_reserve_holdback_hybrid`, false);
        const default_enable_reserve_holdback = get(state, `user.factoring.data.default_enable_reserve_holdback`, false);
        const useNewNFRequest = get(state, `user.factoring.data.use_new_non_factored_funding_requests`, false);
        const terms = getBrokerTerms(state);
        const formattedItems = formattedData(terms);
        const paymentSpeed = find(formattedItems, ['value', selectorForm(state, 'payout_days')]) || {};
        const nonFactored = selectorForm(state, 'non_factored');
        const billOutOnly = selectorForm(state, 'bill_out_only');
        const brokerFactoringRate = get(state, `resource.user[${state.user.id}].data.factoring_discount_rate`, 3.5);
        const currentBrokerRate = paymentSpeed.discountRate || brokerFactoringRate;
        const carrier = selectorForm(state, 'carrier');
        const hasFactoring = Boolean(get(carrier, 'payment_profile.factoring_company.name', null));
        if (hasFactoring) {
          factoringCompanyPaymentTerm = isEmpty(find(terms, ['payee', 'factoring_company'])) ? factoringCompanyPaymentTerm : find(terms, ['payee', 'factoring_company']);
        }
        const paymentProfileAccounts = get(carrier, 'payment_profile.accounts', [])
        let paymentMethods = [];
        if (get(carrier, 'payment_profile.factoring_company.name', null)) {
          if (get(carrier, 'factoring_company_relation.enable_pay_via_check', false) || get(carrier, 'factoring_company_relation.account.account_number_redacted', '')) {
            paymentMethods.push({
              text: `${get(carrier, 'factoring_company_relation.factoring_company_name', '').toUpperCase()} ${get(carrier, 'factoring_company_relation.account.account_number_redacted', '') ? ` ****${get(carrier, 'factoring_company_relation.account.account_number_redacted', '')}` : '- CHECK'}`,
              fee: '',
              value: get(carrier, 'factoring_company_relation.account.method', 'ACH') === 'CHECK' && get(carrier, 'factoring_company_relation.enable_pay_via_check', false) ? 'check' : 'ach',
            })
          } else {
            paymentMethods.push({
              text: `${get(carrier, 'factoring_company_relation.factoring_company_name', '').toUpperCase()} - ACH`,
              fee: '',
              value: 'ach',
            })
          }
        }
        else if (paymentProfileAccounts.length) {
          paymentMethods = compact(map(paymentProfileAccounts, account => {
            switch (account.method) {
              case 'ACH':
                return {
                  text: `ACH - ${account.account_number_redacted}`,
                  fee: ' - 1 day (Free)',
                  value: 'ach',
                };
                break;
              case 'CHECK':
                return {
                  text: 'Pay via Check',
                  fee: '',
                  value: 'check',
                };
                break;
            }
          }))
        };
        const amount = selectorForm(state, 'amount') || 0;
        const debtor = selectorForm(state, 'bill_to') || {};
        const debtorSplit = selectorForm(state, 'pass_debtor_rate_to_carrier') || false;
        const debtorDiscount = get(debtor, 'discount_rate', 0);
        const brokerPercentageFieldRate = selectorForm(state, 'broker_rate');
        const discountRate = paymentSpeed.discountRate || get(carrier, 'factoring.discount_rate', currentBrokerRate);
        const brokerPercentageRate = get(carrier, 'factoring_fee_split_rate', 100) || paymentSpeed.splitFee;
        const carrierDiscountRate = discountRate - (discountRate * ((brokerPercentageFieldRate >= 0 ? brokerPercentageFieldRate : brokerPercentageRate) / 100));

        const additionRateDebtor = debtorDiscount - brokerFactoringRate;
        const additionRate = (additionRateDebtor > 0 || additionRateDebtor === 0 || additionRateDebtor) ? additionRateDebtor : get(carrier, 'factoring.discount_rate', currentBrokerRate);
        const displayAdditionalRate = debtorDiscount && additionRate > 0;
        const contractTypeInitialValue = reduce(enabledContractTypes, (results, value, key) => value ? concat(results, [key]) : results, [])[0];

        return {
          factoringId: get(state, 'user.factoring.data.id', "id"),
          billOutOnly,
          formattedItems,
          user: get(state, `resource.user[${state.user.id}].data`, {}),
          terms,
          brokerPercentageRate,
          carrierDiscountRate: parseFloat(carrierDiscountRate).toFixed(2),
          shipperOffset: selectorForm(state, 'shipper_offset'),
          amount,
          currentBrokerRate,
          allowSelfFinanceFrDeclinedDebtor,
          allowSelfFinanceFrApprovedDebtor,
          initialValues: {
            apply_reserve_holdback: enableReserveHoldbackHybrid ? default_enable_reserve_holdback : false,
            payment_method: 'ach',
            debtor_split: false,
            non_factored: false,
            bill_out_only: false,
            allowFrWithoutCarrier,
            allowNegativeMargin,
            negativeMarginLimit,
            negativeMarginValue,
            allowSelfFinanceFr,
            selfFinanceFrRemainingBalance,
            selfFinanceFrLimit,
            line_items: [],
            allowLineItemizedItems,
            enableHideDebtor,
            funding_request_type: defaultFrTypeApi || contractTypeInitialValue,
            self_finance_requested: isSelfFinanceDefaultFR,
            contract_type: defaultFrTypeApi || contractTypeInitialValue,
            allowSelfFinanceFrDeclinedDebtor,
            allowSelfFinanceFrApprovedDebtor,
            allowSelfFinanceApprovedDebtorsOutOfCredit,
            ...ownProps.initialValues,
          },
          allowSelfFinanceApprovedDebtorsOutOfCredit,
          paymentSpeedSplitFee: paymentSpeed.splitFee,
          paymentMethods,
          additionRate: parseFloat(additionRate).toFixed(2),
          debtorDiscount,
          debtorSplit,
          displayAdditionalRate,
          hasFactoring,
          factoringCompanyPaymentTerm,
          nonFactored,
          factoringCreateNonFactoredFr,
          allowSelfFinanceFr,
          enabledContractTypes,
          holdBack,
          selfFinanceFrLimit,
          selfFinanceFrRemainingBalance,
          formValues: selectorForm(state, 'apply_reserve_holdback', 'amount', 'carrier_amount', 'bill_to', 'carrier',
            'payout_days', 'pass_debtor_rate_to_carrier', 'shipper_offset', 'broker_rate', 'non_factored', 'bill_out_only', 'self_finance_requested',
            'funding_request_type', 'payment_profile_amount'),
          enableReserveHoldbackHybrid,
          allowLineItemizedItems,
          enableHideDebtor,
          debtor,
          carrier,
          useNewNFRequest,
        };
      }
      catch (e) {
        console.log('ERROR', e);
      }
    },
    dispatch => ({
      fetchTerms: (...args) => dispatch(fetchTerms(...args)),
      change: (...data) => dispatch(change(FORM_NAME, ...data)),
      // blur: (value, state) => dispatch(blur(FORM_NAME, value, state)),
      closeModal: (...args) => dispatch(closeModal(...args)),
      fetchFactoringProfile: (...args) => dispatch(fetchFactoringProfile(...args)),
      fundingRequestCalculations: (...args) => dispatch(fundingRequestCalculations(...args)),
    }),
    null,
    { withRef: true },
  ),
  withStateHandlers(({ is_reserve_holdback = false, reserve_amount = 0, payment_profile_payable = 0, payment_profile_discount_rate = 0,
    factoring_fee = 0, payment_profile_fee = 0, factoring_payable = 0, total_comfreight_fee = 0, loading = false, display = false, carrierNotFound = false, self_finance_requested = false,
    carrier_line_haul = 0,
    qp_total_fee = 0,
    carrier_qp_fee = 0,
    carrier_total_amount = 0,
    broker_carrier_advance = 0,
    broker_self_finance_fee = 0,
    broker_qp_profit = 0,
    self_finance_total_amount = 0,
    factoring_fee_split_rate = 0,
    discount_rate_comfreight = 0,
    formWarning = {},
    openPo = false,
    poRelatedInvoice = [],
    self_finance_fee_rate_info = 0,
  }) =>
  ({
    is_reserve_holdback, reserve_amount, payment_profile_payable, payment_profile_discount_rate,
    factoring_fee, payment_profile_fee, factoring_payable, total_comfreight_fee, loading, display, self_finance_requested, carrier_line_haul,
    qp_total_fee,
    carrier_qp_fee,
    carrier_total_amount,
    broker_carrier_advance,
    broker_self_finance_fee,
    broker_qp_profit,
    self_finance_total_amount,
    factoring_fee_split_rate,
    discount_rate_comfreight,
    formWarning,
    openPo,
    poRelatedInvoice,
    self_finance_fee_rate_info,
  }), {
    calculation: props => ({ is_reserve_holdback, reserve_amount, payment_profile_payable, payment_profile_discount_rate,
      factoring_fee, payment_profile_fee, factoring_payable, total_comfreight_fee, self_finance_requested,
      carrier_line_haul,
      qp_total_fee,
      carrier_qp_fee,
      carrier_total_amount,
      broker_carrier_advance,
      broker_self_finance_fee,
      broker_qp_profit,
      self_finance_total_amount,
      factoring_fee_split_rate,
      discount_rate_comfreight,
      self_finance_fee_rate_info,
    }) => {
      return {
        is_reserve_holdback, reserve_amount, payment_profile_payable, total_comfreight_fee, payment_profile_discount_rate,
        factoring_fee, payment_profile_fee, factoring_payable, self_finance_requested, carrier_line_haul,
        qp_total_fee,
        carrier_qp_fee,
        carrier_total_amount,
        broker_carrier_advance,
        broker_self_finance_fee,
        broker_qp_profit,
        self_finance_total_amount,
        factoring_fee_split_rate,
        discount_rate_comfreight,
        self_finance_fee_rate_info,
      };
    },
    setLoading: props => loading => ({ loading }),
    setDisplay: props => display => ({ display }),
    setCarrierNotFound: props => carrierNotFound => ({ carrierNotFound }),
    setFormWarning: props => formWarning => ({ formWarning }),
    setOpenPo: ({ openPo }) => () => ({ openPo: !openPo }),
    setPoRelatedInvoice: props => poRelatedInvoice => ({ poRelatedInvoice }),
  }),
  lifecycle({
    async componentWillMount() {
      const { fetchTerms, user, fetchFactoringProfile } = this.props;
      await fetchFactoringProfile();
      await fetchTerms(user.factoring_id);
    },
    async componentDidUpdate(prevProps) {
      const { terms, change, formattedItems, factoringCompanyPaymentTerm, formValues, fundingRequestCalculations, calculation, setLoading, setDisplay, enableHideDebtor } = this.props;
      if (!shallowEqual(terms, prevProps.terms) && !isEmpty(formattedItems) && !get(formValues, 'payout_days', undefined)) {
        const defaultPayment = find(formattedItems, ['is_default', true]) || {};
        const splitFee = defaultPayment.splitFee;
        const defaultPaymentSpeed = defaultPayment.value;
        change('payout_days', defaultPaymentSpeed);
        change('broker_rate', splitFee);
      }
      if (!shallowEqual(factoringCompanyPaymentTerm, prevProps.factoringCompanyPaymentTerm)) {
        change('payout_days', factoringCompanyPaymentTerm.payout_days);
        change('broker_rate', factoringCompanyPaymentTerm.factoring_fee_split_rate);
      }
      if (!shallowEqual(formValues, prevProps.formValues)) {
        const getCalculation = async () => {
          setLoading(true);
          setDisplay(false);
          try {
            const res = await fundingRequestCalculations({
              payment_profile_amount: formValues.payment_profile_amount || 0,
              payment_profile_invoice_amount: formValues.payment_profile_amount || 0,
              invoice_amount: formValues.amount,
              debtor: get(formValues, 'bill_to.id', NO_DEBTOR_ID),
              factored: !formValues.non_factored,
              bill_out_only: formValues.bill_out_only,
              self_finance_requested: formValues.self_finance_requested,
              contract_type: formValues.funding_request_type,
              create_rates_revision: false,
              ...(() => {
                if (!formValues.non_factored) {
                  return {
                    payment_profile_invoice_amount: formValues.carrier_amount,
                    payment_speed: parseInt(get(formValues, 'payout_days', 0).match(/\d+/g)[0], 0),
                    payment_profile: formValues.carrier.payment_profile_id,
                    shipper_off_fee: formValues.shipper_offset,
                    factoring_fee_split_rate: get(formValues, 'broker_rate', 0),
                    offset_fee_to_carrier: formValues.pass_debtor_rate_to_carrier,
                    apply_reserve_holdback: formValues.apply_reserve_holdback,
                  };
                }
                if (formValues.non_factored && !formValues.bill_out_only) {
                  return {
                    payment_profile_invoice_amount: formValues.carrier_amount,
                    payment_profile: formValues.carrier.payment_profile_id,
                  };
                }
              })(),
            });
            calculation(res);
            setInterval(() => {
              setLoading(false);
              setDisplay(true);
            }, 1000);
          }
          catch (e) {
            setLoading(false);
            setDisplay(false);
          }
        };
        if (formValues.amount && formValues.non_factored && !isEmpty(formValues.bill_to)) {
          return await getCalculation();
        }
        if (formValues.amount && ['SELF_FINANCE_NO_INVOICE', 'SELF_FINANCE_WITH_INVOICE'].includes(formValues.funding_request_type) && enableHideDebtor && !isEmpty(formValues.carrier)) {
          return await getCalculation();
        }
        if (formValues.amount && formValues.funding_request_type === 'SELF_FINANCE_NO_INVOICE' && !enableHideDebtor && !isEmpty(formValues.carrier)) {
          return await getCalculation();
        }
        if (formValues.amount && formValues.carrier_amount && !isEmpty(formValues.bill_to) && !isEmpty(formValues.carrier) &&
          formValues.payout_days && !isUndefined(formValues.broker_rate)) {
          return await getCalculation();
        }

      }
    },
  }),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    asyncBlurFields: ['purchase_order_number'],
    asyncValidate,
    validate: fields => validate(fields, {
      amount: (value, options, key, attributes, globalOptions) => {  // amount = shipper and invoice amount
        if (options.allowSelfFinanceFr && options.self_finance_requested) {
            const limit = Math.min(options.selfFinanceFrRemainingBalance, options.selfFinanceFrLimit);
            return {
                presence: true,
                numericality: {
                    greaterThanOrEqualTo: options.carrier_amount,
                    notGreaterThanOrEqualTo: `must be greater or equal to ${formatPennies(options.carrier_amount)}`,
                    lessThanOrEqualTo: limit,
                    notLessThanOrEqualTo: `Self finance funding Request cannot be more than ${formatPennies(limit)}`,
                }
            }
        } else {  // normal factored, not self finance
            return {
                presence: true,
                numericality: {
                    greaterThanOrEqualTo: 100,
                    notGreaterThanOrEqualTo: 'must be greater or equal to $1',
                }
            }
        }
    },
    carrier_amount: (value, options, key, attributes, globalOptions) => {
      if (options.allowNegativeMargin && options.negativeMarginLimit > 0 && !options.self_finance_requested) {
        return {
          presence: true,
          numericality: {
            greaterThan: 100,
            notGreaterThan: 'must be greater than $1',
            lessThanOrEqualTo: options.amount + options.negativeMarginValue,  // negativeMarginValue is max neg margin
            notLessThanOrEqualTo: `Negative Margin cannot be more than ${formatPennies(options.negativeMarginValue)}`,
          }
        }
      } else { // no negative margin allowed
          return {
              presence: true,
              numericality: {
                  greaterThan: 100,
                  notGreaterThan: 'must be greater than $1',
                  lessThanOrEqualTo: options.amount,  // invoice amount
                  notLessThanOrEqualTo: `must be less or equal to ${formatPennies(options.amount)}`,
              }
          }
      }
    },
      carrier: (value, options, key, attributes, globalOptions) => {
        if (options.allowFrWithoutCarrier) {
          return;
        }
        return {
          presence: true
        }
      },
      bill_to: (value, options, key, attributes, globalOptions) => {
        try{
          let errors = {};
          if (options.funding_request_type !== 'SELF_FINANCE_NO_INVOICE') {
            errors = {
              isNoDebtor: {
                funding_request_type: options.funding_request_type
              }
            }
          }
          if (['SELF_FINANCE_NO_INVOICE', 'SELF_FINANCE_WITH_INVOICE'].includes(options.funding_request_type) && !options.enableHideDebtor) {
            if (!options.allowSelfFinanceFrDeclinedDebtor && !options.allowSelfFinanceFrApprovedDebtor) {
              return {
                presence: true,
                unauthorizedUseDebtor: true,
                ...errors,
              }
            }
            if (!options.allowSelfFinanceFrDeclinedDebtor) {
              return {
                presence: true,
                debtorDeclined: {
                  message: "^You are not authorized to self-finance declined credit status debtors"
                },
                ...errors,
              }
            }
            if (options.allowSelfFinanceApprovedDebtorsOutOfCredit
              && options.allowSelfFinanceFrDeclinedDebtor
              && !options.allowSelfFinanceFrApprovedDebtor
              && options.carrier_amount > value.credit_available) {
              return;
            }
            if (!options.allowSelfFinanceFrApprovedDebtor) {
              return {
                presence: true,
                isApprovedDebtor: {
                  message: "^You are not authorized to self-finance approved credit status debtors"
                },
                ...errors,
              }
            }
          }

          if (options.funding_request_type === 'SELF_FINANCE_NO_INVOICE') {
            return;
          }

          console.log("ERRORS", errors, value)
          if (!['SELF_FINANCE_NO_INVOICE', 'SELF_FINANCE_WITH_INVOICE'].includes(options.funding_request_type) && !options.enableHideDebtor) {
            return {
              presence: true,
              ...errors
            }
          }
          return errors;
        } catch(e){
        }
      },
      load_number: {
        presence: true,
      },
      broker_rate: {
        presence: true,
      },
      payment_method: {
        presence: true,
      },
      line_items: (value, options, key, attributes, globalOptions) => {
        if (options.allowLineItemizedItems) {
          return {
            array: {
              amount: {
                presence: true,
                numericality: {
                  greaterThan: 0,
                }
              },
              category: {
                presence: true,
              },
              description: {
                presence: !!find(value, ['category', 'Other'])
              }
            }
          }
        }
        return;
      },
    }),
  }),
)(DebtorCarrierPaymentForm);
