import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import get from 'lodash/get';


export default compose(
  connect(
    state => {
      return {
        user_id: state.user.logged_in ? get(state.resource.user, [state.user.id, 'data', 'id']) : null,
        user_is_not_subscribed: state.user.logged_in && !get(state.resource.user, [state.user.id, 'data', 'permissions', 'is_subscribed']),
        verify_email_required: get(state.resource.user, [state.user.id, 'data', 'has_verified_email']) === false,
      };
    },
  ),
)(({ user_id, user_is_not_subscribed, verify_email_required }) => {
  const messages = [];

  if (verify_email_required) {
    messages.push(
      <div className='alert alert-danger'>
        Verify your account to enable alerts. <Link to='/settings/notifications'>More here.</Link>
      </div>
    );
  }
  if (user_is_not_subscribed) {
    if (user_id !== '3724ed14-bbf4-4085-9d56-410eda554279') { // https://github.com/ComFreight/frontend/issues/1594
      messages.push(
        <div className='alert alert-success' style={{ padding: '7px 15px', fontSize: 15 }}>
          <Link to='/settings/billing/subscription'><b>Upgrade to Full Access</b></Link>
        </div>
      );
    }
  }
  if (messages.length) {
    return <div className='App__alerts'>{messages}</div>;
  }
  return null;
});
