import React from 'react';
import PropTypes from 'prop-types';
import DropdownList from 'react-widgets/lib/DropdownList';
import cs from 'classnames';
import { get } from 'lodash';

const LabeledDropdown = ({
  input: { onChange, ...input },
  meta = {},
  data,
  label,
  labelProps = {},
  containerProps = {},
  placeholder,
  textField = 'text',
  valueField = 'value',
  horizontal,
  submitOnChange = false,
  onValueChange = () => ({}),
  onObjectChange = () => ({}),
  ...rest
}) =>
  <div
    className={cs('form-group', { 'has-error': meta.touched && meta.error })}
    {...containerProps}
    >
    {
      label ?
        <label
          {...labelProps}
          className={cs('control-label', labelProps.className, {
            'col-xs-2': horizontal,
          })}
          >
          {label}
        </label>
        : null
    }
    <div
      className={cs({
        'col-xs-10': horizontal,
      })}
      >
      <DropdownList
        data={data}
        placeholder={placeholder || ''}
        textField={textField}
        valueField={valueField}
        onChange={val => {
          onValueChange(get(val, [valueField], val));
          onChange(get(val, [valueField], val));
          onObjectChange(val);
          if (submitOnChange) {
            setTimeout(submitOnChange);
          }
        }}
        {...input}
        {...rest}
      />
    </div>
    {meta.touched && meta.error && <div className='help-block'>{meta.error}</div>}
  </div>
  ;

LabeledDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default LabeledDropdown;
