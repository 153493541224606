/**
 * Component that displays the sign up form
 * @module components/pure/form/SignupForm
 * @since 3.0.0
 * @requires actions/ui
 * @requires actions/user
 * @requires components/pure/Header
 * @requires components/pure/form/inputs/LabeledDropdown
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/inputs/GoogleLocationInput
 * @requires components/pure/form/inputs/CleaveInput
 * @requires components/pure/form/inputs/CheckboxInput
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { Link, browserHistory } from 'react-router-v4';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import validate from 'validate.js';
import { USER_TYPE } from 'helpers';
import { openModal } from 'actions/ui';
import { getDataFromDOT, getDataFromMC, signup, validateEmail } from 'actions/user';
import Header from 'components/pure/Header';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import MCorDOTInputs from 'components/pure/form/inputs/MCorDOTInputs';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';

import normalizeFormPhone from 'helpers/normalizeFormPhone';
import has from 'lodash/has';
import RadioInput from './inputs/RadioInput';
import { useHubspotTracking } from '@haulpay/hooks';

const FORM_NAME = 'SignupForm';
const selector = formValueSelector(FORM_NAME);

const shouldShowOptionalInputs = user_type => [
  USER_TYPE.BROKER,
  'broker/carrier',
  USER_TYPE.CARRIER,
  'owneroperator',
].includes(user_type);

const optionalequipmentType = user_type => [
  USER_TYPE.CARRIER,
].includes(user_type);

export default compose(
  connect(
    (state, ownProps) => {
      const { setTrackEvent } = useHubspotTracking();
      const isSourceMobile = ['mobile-ios', 'mobile-android'].includes(get(ownProps, 'location.query.source', false));
      return {
        userType: selector(state, 'userType'),
        initialValues: {
          userType: isSourceMobile ? USER_TYPE.CARRIER : '',
          location: {},
          selected_number: MCorDOTInputs.MC,
          language: 'en',
        },
        isSourceMobile,
        setTrackEvent,
      };
  },
  ),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
    onSubmit: async (fields, dispatch, { setTrackEvent }) => {
      let type = typeof fields.userType === 'string' ? fields.userType : fields.userType.value;
      if (type === 'broker/carrier') {
        type = USER_TYPE.BROKER;
      }
      if (type === 'owneroperator') {
        type = USER_TYPE.CARRIER;
      }
      const data = {
        email: fields.email,
        password: fields.password,
        type,
        language: fields.language,
        signup_url: window.location.href,
        referrer_url: document.referrer || '',
        [type]: {
          dba: fields.dba,
          contact_phone: normalizeFormPhone(fields, 'contact_phone'),
          contact_name: fields.contact_name,
          mc: fields.mc,
          dot: fields.dot,
          language: fields.language,
          equipment_types: fields.equipment_types ? fields.equipment_types.map(tt => tt.value) : undefined,
          enable_sms_alerts: fields.enable_sms_alerts
        },
      };
      if (fields.location) {
        if (typeof fields.location === 'string') {
          data[type].address = fields.location;
        }
        else {
          data[type] = {
            ...data[type],
            address: fields.location.street_one,
            city: fields.location.city,
            state: fields.location.state,
            zip: fields.location.zip,
            country: fields.location.country,
          };
        }
      }
      try {
        await dispatch(signup(data));
        setTrackEvent('sign-up', omit(data, ['password']))
        browserHistory.push('/');
      } catch (err) {
        console.log(err);
        dispatch(openModal('error', err));
      }
    },
    validate(fields) {
      if (fields.type === 'broker/carrier') {
        fields.type = USER_TYPE.BROKER;
      }
      if (fields.type === 'owneroperator') {
        fields.type = USER_TYPE.CARRIER;
      }
      let errors = validate(fields, {
        userType: {
          presence: true,
        },
        email: {
          presence: true,
          email: true,
        },
        password: {
          presence: true,
          length: {
            minimum: 4,
            maximum: 30,
          },
        },
        password_confirm: {
          presence: true,
          equality: {
            attribute: 'password',
            message: '^Must be equal to password',
          },
        },
        contact_phone: {
          presence: true,
          phone: true,
        },
        dba: {
          presence: true,
        },
        termsofservice: {
          isTrue: true,
        },
      });
      if (typeof fields.location !== 'string' && !(fields.location.city && fields.location.state)) {
        errors.location = 'required';
      }
      if (optionalequipmentType(fields.userType)) {
        const equipmentErrors = validate(fields, {
          equipment_types: {
            presence: true,
            length: {
              minimum: 1,
              tooShort: '^At least 1 Equipment Type required',
            },
          },
        });
        //  if (fields.equipment_types === 0) {
        //   equipmentErrors.equipment_types = 'Please enter at least 1 Equipment Type';
        // }
        if (equipmentErrors) {
          errors = { ...errors, ...equipmentErrors };
        }
      }
      if (shouldShowOptionalInputs(fields.userType)) {
        const mcOrDotErrors = validate(fields, {
          mc: {
            numericality: {
              notValid: '^Please enter numbers',
            },
          },
          dot: {
            numericality: {
              notValid: '^Please enter numbers',
            },
          },
        });
        if ((!fields.mc && fields.selected_number === MCorDOTInputs.MC) || (!fields.dot && fields.selected_number === MCorDOTInputs.DOT)) {
          mcOrDotErrors.mc = mcOrDotErrors.dot = 'Please enter an MC# or DOT#';
        }
        if (mcOrDotErrors) {
          errors = { ...errors, ...mcOrDotErrors };
        }
      }
      return errors;
    },
    warn(fields) {
      if (fields.userType === USER_TYPE.SHIPPER && (/(trucking|logistics?)/i).test(fields.dba)) {
        return {
          dba: 'If you are a trucking or logistics company please select Carrier/Broker from Company Type above to sign up for correct account type.',
        };
      }
    },
    asyncBlurFields: ['email', 'dot', 'mc'],
    async asyncValidate(fields, dispatch, props, blurredField) {
      switch (blurredField) {
        case 'email':
          return validateEmail(fields.email)
            .catch(err => console.warn(err))
            .then(json => {
              if (json && json.status === 'failure') {
                throw { // eslint-disable-line no-throw-literal
                  email: <div>This email already exists, please use a different email address or <Link to='/login'>login here</Link> or reset your password for your account <Link to='/resetpassword'>here</Link>.</div>,
                };
              }
            })
          ;
        case 'dot':
          if (!fields.dot) {
            return;
          }
          return getDataFromDOT(fields.dot)
            .then(data => {
              if (!has(data, 'search_query')) {
                dispatch(change(FORM_NAME, 'dba', data.dba || data.name));
                dispatch(change(FORM_NAME, 'contact_phone', data.phone));
                dispatch(change(FORM_NAME, 'location', {
                  street: data.street_one,
                  city: data.city,
                  state: data.state,
                  zip: data.zip,
                  country: data.country,
                }));
              }
            })
            .catch(err => console.warn(err))
          ;
        case 'mc':
          if (!fields.mc) {
            return;
          }
          return getDataFromMC(fields.mc)
            .then(data => {
              if (!has(data, 'search_query')) {
                dispatch(change(FORM_NAME, 'dba', data.dba || data.name));
                dispatch(change(FORM_NAME, 'contact_phone', data.phone));
                dispatch(change(FORM_NAME, 'location', {
                  street: data.street_one,
                  city: data.city,
                  state: data.state,
                  zip: data.zip,
                  country: data.country,
                }));
              }
            })
            .catch(err => console.warn(err))
          ;
      }
    },
  }),
)(({ userType, handleSubmit, submitting, isSourceMobile }) =>
  <div className='signup' name='signup'>
      <div>
          <Header style={{ marginTop: 0 }}>
              <div>Sign Up Free!</div>
              <div style={{ fontSize: 17 }}>Create your business profile.</div>
          </Header>
          <Field
              disabled={isSourceMobile}
              name="userType"
              component={LabeledDropdown}
              placeholder="What type of company are you?"
              data={[
                  { text: 'Shipper', value: USER_TYPE.SHIPPER },
                  { text: 'Carrier', value: USER_TYPE.CARRIER },
                  { text: 'Broker', value: USER_TYPE.BROKER },
                  { text: 'Dispatch Company', value: USER_TYPE.DISPATCHER },
                  { text: 'Carrier/Broker', value: 'broker/carrier' },
                  { text: 'Owner Operator', value: 'owneroperator' }
              ]}
              valueField="value"
              textField="text"
              className="input-lg"
          />
          <Field
              name="email"
              component={LabeledInput}
              placeholder="Email Address"
              type="email"
              className="input-lg"
              hasAsyncValidate={true}
              normalize={value => value.toLowerCase()}
          />
          <Field name="password" component={LabeledInput} placeholder="Create Password" type="password"
                 className="input-lg" />
          <Field name="password_confirm" component={LabeledInput} placeholder="Confirm Password" type="password"
                 className="input-lg" />
          <Field name="contact_name" component={LabeledInput} placeholder="Contact Name" className="input-lg" />
          {
              shouldShowOptionalInputs(userType) ?
                  <MCorDOTInputs
                      containerProps={{ style: { marginTop: '1em' } }}
                      fieldProps={{ className: 'input-lg' }}
                  />
                  : null
          }
          <Field name="dba" component={LabeledInput} placeholder="Business Name" className="input-lg" />
          {
              optionalequipmentType(userType) ?
                  <Field
                      name="equipment_types"
                      component={MultiTrailerInput}
                      placeholder="Equipment Type"
                      style={{ height: '46px', fontSize: '18px', lineHeight: '1.3333333', borderRadius: '6px' }}
                  />
                  : null
          }
          <Field
              name="location"
              component={GoogleLocationInput}
              placeholder="Business Address"
              setValueWithoutConfirming={true}
              specificity={GoogleLocationInput.specificity.ADDRESS}
              inputProps={{
                  className: 'input-lg'
              }}
          />
          <RadioInput
              inline={true}
              values={[{ value: 'en', label: 'EN' }, { value: 'es', label: 'ES' }]}
              label="Language preference?"
              name="language"
          />
          <div className="row">
              <div className="col-md-6">
                  <Field name="contact_phone" component={PhoneInput} label="My Phone Number" className="input-lg" />
              </div>
              <div className="col-md-6">
                  <Field name="contact_phone_ext" component={LabeledInput} label="Phone Ext." type="number"
                         className="input-lg" />
              </div>
          </div>
          <div name="enable_sms_alerts">
              <Field
                  name="enable_sms_alerts"
                  component={CheckboxInput}
                  label="I would like to receive important text messages regarding my operations"
              />
          </div>
          <div name="termsofservice">
              <Field
                  name="termsofservice"
                  component={CheckboxInput}
                  label={<span>I agree to the <a href="//comfreight.com/tos" target="_blank" rel="noopener">HaulPay Terms of Service</a></span>}
                  errorLabel="Please accept the terms of service."
              />
          </div>
          <button className="btn-transaction col-xs-12" onClick={handleSubmit} disabled={submitting}>Sign Up</button>
      </div>
      <hr className="visible-xs-block" />
      <div>
          <div><b>Match Trucks to Loads, Automate Processes and Payments Today!</b></div>
          <div>
              <img src="/public/image/icon/pallets.png" />
              Match Freight
          </div>
          <div>
              <img src="/public/image/icon/truck_hollow.png" />
              Finance Growth
          </div>
      <div>
        <img src='/public/image/icon/mobile.png' />
        Fastest Payments
      </div>
    </div>
  </div>
);
