/**
 * Custom validation functions for information validation throughout the app using {@link https://validatejs.org/}
 * @module validate
 * @since 3.0.0
 */
import validate from 'validate.js';
import convert from 'convert-units';
import phoneNumber from 'google-libphonenumber/dist/libphonenumber';
import moment from 'moment';
import _, { isEmpty } from 'lodash';
import { NO_DEBTOR_ID } from './helpers';


/**
 * Phone validator
 * @param {object} [options]
 * @param {string[]} [options.region] - the phone regions to include in the validation
 * @param {string} [options.notValid] - message to return as an error if the phone number doesn't pass validation
 */
validate.validators.phone = (value, options) => {
  if (validate.isEmpty(value)) {
    return;
  }
  const phoneUtil = phoneNumber.PhoneNumberUtil.getInstance();
  const defaultOptions = {
    region: ['US', 'MX', 'CA'],
    notValid: '^Not a valid phone number',
  };
  if (typeof options === 'string') {
    options = {
      ...defaultOptions,
      notValid: options,
    };
  }
  else if (typeof options === 'boolean') {
    options = defaultOptions;
  }
  else {
    options = {
      ...defaultOptions,
      ...options,
    };
  }
  const regions = Array.isArray(options.region) ? options.region : [options.region];
  const error = [validate.format(options.notValid, { value })];
  const isValid = regions.some(region => {
    let number;
    try {
      number = phoneUtil.parse(value, region);
    }
    catch (e) {
      return false;
    }
    return phoneUtil.isValidNumber(number);
  });
  if (!isValid) {
    return error;
  }
};

/**
 * Custom date time parse and formatting function.
 * Differs from the native validate.js datetime validator in that this allows you to pass `options.parse` and `options.format` in this validator specifically.
 * For params, see {@link https://validatejs.org/#validators-datetime}
 */
validate.validators.cdatetime = (value, options) => {
  return validate.validators.datetime.call({
    ...validate.validators.datetime,
    parse: options.parse || (value => Number(value)),
    format: options.format || (value => moment(value).format('l')),
    tooEarly: options.tooEarly || validate.validators.datetime.tooEarly,
    tooLate: options.tooLate || validate.validators.datetime.tooLate,
    notValid: options.notValid || validate.validators.datetime.notValid,
  }, value, options);
};
validate.validators.datetime.notValid = '^Must be a valid date';
validate.validators.datetime.tooEarly = '^Must be no earlier than %{date}';
validate.validators.datetime.tooLate = '^Must be no later than %{date}';

/**
 * Custom date time (above) validation that is relative to another field in the passed values object
 * @param {object} options
 * @param {string} [options.earliest] - the name of the key in the values object that this datetime should be earlier in relation to
 * @param {string} [options.latest] - the name of the key in this values object that this datetime should be later in relation to
 */
validate.validators.creldatetime = (value, options, key, attributes) => {
  options = {
    ...options,
    earliest: attributes[options.earliest],
    latest: attributes[options.latest],
  };
  return validate.validators.cdatetime(value, options);
};

/**
 * Custom date time validation where you can specify a duration around another time that this must be greater than
 * @param {obect} options
 * @param {string} options.othertime - the name of the key in the values object that this datetime should be compared to
 * @param {number} options.amount - the amount of time difference there should be
 * @param {string} options.parseThis - the string format used to parse this date for comparison
 * @param {string} options.parseOther - the string format used to parse the comparator date for comparison
 * @param {string} options.message - the error message
 */
validate.validators.creldatetime_duration = (value, options, key, attributes) => {
  const thisTime = value;
  const otherTime = attributes[options.othertime];
  if (Math.abs(moment(thisTime, options.parseThis).diff(moment(otherTime, options.parseOther))) < options.amount) {
    return [options.message];
  }
};

/**
 * Multiple nested presence checker. Checks an object for present keys
 * @param {string[] | object} options - either the array of required field names, or the options object
 * @param {string[]} [options.required] - the array of required field names
 * @param {function} [options.formatKey = key => key] - a function to format the missing key before being added to the error message
 * @param {string} [options.message = '^Must include %{missing}'] - the error message template
 */
validate.validators.containsKey = (value, options) => {
  const defaults = {
    formatKey: key => key,
    message: '^Must include %{missing}',
  };
  options = { ...defaults, ...options };
  let validationbLength = 0;
  if (value.length) {
    validationbLength = _.filter(value, object => {
      return object[options.required] !== undefined;
    }).length;
  }
  if (validationbLength !== value.length) {
    return validate.format(options.message, { missing: options.required });
  }
};
/**
 * Multiple nested presence checker. Checks an object for present keys
 * @param {string[] | object} options - either the array of required field names, or the options object
 * @param {string[]} [options.required] - the array of required field names
 * @param {function} [options.formatKey = key => key] - a function to format the missing key before being added to the error message
 * @param {string} [options.message = '^Must include %{missing}'] - the error message template
 */
validate.validators.multipresence = (value, options) => {
  const defaults = {
    formatKey: key => key,
    message: '^Must include %{missing}',
  };

  if (Array.isArray(options)) {
    options = { ...defaults, required: options };
  }
  else {
    options = { ...defaults, ...options };
  }

  const missingKeys = [];
  options.required.forEach(subkey => {
    if (!value || validate.isEmpty(value[subkey])) {
      missingKeys.push(options.formatKey(subkey));
    }
  });
  if (missingKeys.length) {
    return validate.format(options.message, { missing: missingKeys.join(', ') });
  }
};
/**
 * The same as multipresence, but first checks if the key itself is an object first
 */
validate.validators.multipresenceifdefined = (...args) => {
  const [value] = args;
  if (Object.keys(value).filter(key => value[key]).length) {
    return validate.validators.multipresence(...args);
  }
};

/**
 * Array length validator.
 * @param {object} options
 * @param {number} [options.minimum = 0] - the minimum required elements
 * @param {number} [options.maximum = Number.MAX_VALUE] - the maximum allowed elements
 * @param {string} [options.tooMany = 'Must be fewer than %{elements}'] - the error message template when there are too many elements
 * @param {string} [options.tooFew = 'Must be greater than %{elements}'] - the error message template when there are too few elements
 */
validate.validators.alength = (value, options) => {
  if (!Array.isArray(value)) {
    return;
  }
  options = {
    minimum: 0,
    maximum: Number.MAX_VALUE,
    tooMany: 'Must be fewer than %{elements}',
    tooFew: 'Must be greater than %{elements}',
    ...options,
  };
  if (value.length > options.maximum) {
    return [validate.format(options.tooMany, { elements: options.maximum })];
  }
  if (value.length < options.minimum) {
    return [validate.format(options.tooFew, { elements: options.minimum })];
  }
};

/**
 * Field that must be true
 */
validate.validators.isTrue = (value, options) => {
  if (value !== true) {
    return [options.message || 'Must be true'];
  }
};

/**
 * Field Array that must be not empty
 */
validate.validators.isEmptyField = (value, options) => {
  if (isEmpty(value)) {
    return [options.message || 'should not be empty'];
  }
};

/**
 * Field that must be true
 */
validate.validators.debtorDeclined = (value, options) => {
  if (value && ["declined", "declined_3_months", "declined_6_months"].includes(value?.credit_approved)) {
    // return '^This Debtor is currently Declined for credit please make sure to check the text below to submit your request.';
    return [options.message || '^This Debtor is currently Declined for credit'];
  }
};

validate.validators.isApprovedDebtor = (value, options) => {
  if (value && value.credit_approved === 'approved') {
    return [options.message || '^This Debtor is currently Approved for credit'];
  }
};

validate.validators.isNoDebtor = (value, options) => {
  const FUNDING_REQUEST_TYPE_MAPPING = {
    STD_BROKER: 'Factored',
    NON_FACTORED_STANDARD: 'Non-Factored Pay Carrier',
    NON_FACTORED_BILL_OUT: 'Non-Factored Bill Out Only',
    SELF_FINANCE_NO_INVOICE: 'Self-Financed No Invoicing',
    SELF_FINANCE_WITH_INVOICE: 'Self-Financed With Invoicing',
  }
  if (value && value.id === NO_DEBTOR_ID) {
    return [options.message || `^You can not create a ${FUNDING_REQUEST_TYPE_MAPPING[options.funding_request_type]} funding request using no debtor. Please select a debtor or change the funding request type to self finance no invoice.`];
  }
};

validate.validators.unauthorizedUseDebtor = (value, options) => {
  if (value) {
    return [options.message || '^You are not authorized to self-finance approved debtors who have credit remaining.'];
  }
};

validate.validators.array = (arrayItems, itemConstraints) => {
  const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
    const error = validate(item, itemConstraints)
    if (error) errors[index] = { ...error }
    return errors
  }, {})

  return isEmpty(arrayItemErrors) ? null : arrayItemErrors
}
/**
 * File size validator.
 * @param {object} options
 * @param {number} [options.minimim = 0] - the minimum file size in bytes
 * @param {number} [options.maximum = Number.MAX_VALUE] - the maximum file size
 * @param {string} [options.tooBig = 'File "%{filename}" is too big (%{filesize}), max %{maximum}'] - the error message template when the file is too big
 * @param {string} [options.tooSmall = 'File "%{filename}" is too small (%{filesize}), min %{minimum}'] - the error message template when the file is too small
 */
validate.validators.filesize = (value, options) => {
  if (validate.isEmpty(value)) {
    return;
  }
  options = {
    minimum: 0,
    maximum: Number.MAX_VALUE,
    tooBig: '^File "%{filename}" is too big (%{filesize}), max %{maximum}',
    tooSmall: '^File "%{filename}" is too small (%{filesize}), min %{minimum}',
    ...options,
  };
  const errs = [];
  const prettyMaximum = convert(options.maximum).from('B').toBest();
  const prettyMinimum = convert(options.minimum).from('B').toBest();

  const checkFile = file => {
    if (file.size > options.maximum) {
      const prettyUnit = convert(file.size).from('B').toBest();
      errs.push(validate.format(options.tooBig, {
        filename: file.name,
        filesize: `${prettyUnit.val.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${prettyUnit.unit}`,
        maximum: `${prettyMaximum.val.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${prettyMaximum.unit}`,
      }));
    }
    else if (file.size < options.minimum) {
      const prettyUnit = convert(file.size).from('B').toBest();
      errs.push(validate.format(options.tooSmall, {
        filename: file.name,
        filesize: `${prettyUnit.val.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${prettyUnit.unit}`,
        minimum: `${prettyMinimum.val.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${prettyMinimum.unit}`,
      }));
    }
  };
  if (value instanceof FileList) {
    Array.prototype.forEach.call(value, checkFile);
  }
  else {
    checkFile(value);
  }
  if (errs.length) {
    return errs;
  }
};

/**
 * Overwrite numericality validator to allow empty values
 */
const oldNumericality = validate.validators.numericality;
validate.validators.numericality = function numericality(value) {
  if (validate.isEmpty(value)) {
    return;
  }
  return oldNumericality.apply(validate.validators, arguments); // eslint-disable-line prefer-rest-params
};
/**
 * Overwrite url validator to allow schemeless urls
 */
/* eslint-disable prefer-template */
validate.validators.url = function url(value, options) {
  if (validate.isEmpty(value)) {
    return;
  }

  options = validate.extend({}, this.options, options);

  const message = options.message || this.message || 'is not a valid url';
  const schemes = options.schemes || this.schemes || ['http', 'https'];
  const allowLocal = options.allowLocal || this.allowLocal || false;

  if (!validate.isString(value)) {
    return message;
  }

  // https://gist.github.com/dperini/729294
  let regex =
    '^' +
    // protocol identifier
    '(?:(?:' + schemes.join('|') + ')://)?' + // ONE-LINE CHANGE HERE
    // user:pass authentication
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:';

  let tld = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))';

  if (allowLocal) {
    tld += '?';
  }
  else {
    regex +=
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})';
  }

  regex +=
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broacast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
      // host name
      '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
      // domain name
      '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
      tld +
    ')' +
    // port number
    '(?::\\d{2,5})?' +
    // resource path
    '(?:[/?#]\\S*)?' +
  '$';

  const PATTERN = new RegExp(regex, 'i');
  if (!PATTERN.exec(value)) {
    return message;
  }
};
/* eslint-enable prefer-template */


// change default presence error message
validate.validators.presence.message = '^This field is required.';

// Change the default funcionality of error message presentation. Because redux-form expects a single string as the value for an error,
// flatten array of errors into the first string in the array of errors
const { processValidationResults } = validate;
function getFirst(arr) {
  if (!Array.isArray(arr)) {
    return arr;
  }
  return getFirst(arr[0]);
}
validate.processValidationResults = (...args) => {
  const results = processValidationResults(...args);
  const ret = {};
  for (const key in results) {
    ret[key] = getFirst(results[key]);
  }
  return ret;
};