/* global google */
/**
 * Component for displaying an input with Google places autocomplete functionality
 * @module components/pure/form/inputs/GoogleLocationInput
 * @since 3.0.0
 * @property {object} props
 * @property {node} [props.label]
 * @property {string} [props.placeholder]
 * @property {string|string[]} [props.error]
 * @property {object} [props.specificity]
 * @property {any} [props....rest] - props passed to the input component
 */
import 'styles/GoogleLocationInput';
import { inspect } from 'util';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import validate from 'validate.js';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import omit from 'lodash/omit';

import parseGooglePlace from 'helpers/parseGooglePlace';
import getPlacePredictions from 'helpers/getPlacePredictions';
import getPlaceDetails from 'helpers/getPlaceDetails';
import storage from 'datatypes/storage';

import MaterialIcon from 'components/pure/MaterialIcon';


//// actual => google's typing
// street_number => street_number
// street => route
// neighborhood => neighborhood + political + considered cities sometimes
// zip => postal_code
// zip_suffix => postal_code_suffix
// city => locality + political
// city (sometimes) => administrative_area_level_3 + political
// county => administrative_area_level_2 + political
// state => administrative_area_level_1 + political
// country => country + political

const specificity = {
  // street_number, street, neighborhood, city, county, state, country, zip, zip_suffix
  ADDRESS: ['address'],
  // zip, city, county, state, country
  REGION: ['(regions)'],
  // city, county, state, country
  CITY: ['(cities)'],
  // any
  ANY: null,
};

const getSpecificity = spec => {
  switch (spec) {
    case specificity.ADDRESS:
      return 'ADDRESS';
    case specificity.REGION:
      return 'REGION';
    case specificity.CITY:
      return 'CITY';
    case specificity.ANY:
      return 'ANY';
  }
  console.warn('Invalid specificity passed to GLInput: ', spec);
  throw TypeError(`Invalid specificity passed to GLInput: ${spec}`);
};

const history = {};

const LOCALSTORAGE_KEY = 'GLIHistory';
const HISTORY_LIMIT = 3;

export class GoogleLocationInput extends Component {
  constructor(props) {
    super(props);
    const { input } = props;
    let initialValue = '';
    if (typeof input.value === 'object') {
      const components = Object.values(omit(input.value, ['instructions'])).filter(Boolean);
      if (components.length) {
        initialValue = components, ['instructions'].filter(component => component).join(', ');
      }
    }
    else if (typeof input.value === 'string' && input.value.length) {
      initialValue = input.value;
    }
    else if (input.value.formatted_address) {
      initialValue = input.value.formatted_address;
    }

    this.sessionToken = new google.maps.places.AutocompleteSessionToken();

    this.state = {
      hasFocus: false,
      value: get(props, ['input', 'value', 'formatted_address'], initialValue),
      suggestions: null,
      hasEditedSinceSelect: false,
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickAnyway = this.onClickAnyway.bind(this);

    // load history for specificity
    const spec = getSpecificity(props.specificity);
    let hist = history[spec];
    if (!hist) {
      try {
        hist = JSON.parse(storage.getItem(`${LOCALSTORAGE_KEY}-${spec}`));
        history[spec] = hist;
        if (!Array.isArray(hist)) {
          throw new Error(`got something that isn't an array of places from localstorage: ${inspect(hist)}`);
        }
        hist.map(place => {
          const errs = validate({ place }, {
            place: {
              multipresence: ['place_id', 'formatted_address', 'city', 'state'],
            },
          });
          if (errs.place) {
            throw new Error(`got an invalid place in history: ${inspect(place)}`);
          }
        });
      }
      catch (e) {
        storage.removeItem(`${LOCALSTORAGE_KEY}-${spec}`);
        hist = history[spec] = [];
      }
    }
    this.history = hist;

    this.latest_suggestions = Promise.resolve();
    this.latest_details = Promise.resolve();
    this.updateSuggestions = debounce(this.updateSuggestions.bind(this), 625, {
      leading: true,
      trailing: true,
    });
    this.justClicked = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this;
    return state.value !== nextState.value ||
      state.hasFocus !== nextState.hasFocus ||
      state.suggestions !== nextState.suggestions ||

      props.label !== nextProps.label ||
      props.placeholder !== nextProps.placeholder ||
      props.meta !== nextProps.meta
    ;
  }

  componentWillUnmount() {
    this.input = null;
    this.latest_suggestions = null;
    this.latest_details = null;
    this.updateSuggestions = null;
    this.hist = null;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.hasEditedSinceSelect) {
      let locationBeingSet;
      if (typeof nextProps.input.value === 'object') {
        locationBeingSet = get(nextProps.input.value, ['formatted_address'], '');
        if (!locationBeingSet) {
          const components = Object.values(nextProps.input.value).filter(Boolean);
          if (components.length) {
            locationBeingSet = components.filter(component => component).join(', ');
          }
        }
      }
      else if (typeof nextProps.input.value === 'string') {
        locationBeingSet = nextProps.input.value;
      }
      if (this.props.input.value !== nextProps.input.value) {
        this.setState({ value: locationBeingSet });
      }
    }
  }

  static specificity = specificity;

  static propTypes = {
    label: PropTypes.node,
    placeholder: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    dirty: PropTypes.bool,
    touched: PropTypes.bool,

    // expect that these are never changed
    specificity: PropTypes.oneOf(Object.values(specificity)),
  };

  static defaultProps = {
    placeholder: 'Anywhere',
    specificity: specificity.ADDRESS,
  };

  updateHistory(place) {
    if (this.history) {
      const exists = this.history.findIndex(({ place_id } = {}) => place_id === place.place_id);
      if (exists !== -1) {
        this.history.splice(exists, 1);
      }
      this.history.unshift(place);
      if (this.history.length > HISTORY_LIMIT) {
        this.history.pop();
      }
      storage.setItem(`${LOCALSTORAGE_KEY}-${getSpecificity(this.props.specificity)}`, JSON.stringify(this.history));
    }
  }

  parsePlace(place) {
    if (place.address_components === undefined) {
      return;
    }
    const parsedPlace = parseGooglePlace(place);

    this.updateHistory(parsedPlace);
    this.props.input.onChange(parsedPlace);
    this.setState({ hasEditedSinceSelect: false });
  }

  updateSuggestions(input) {
    return this.latest_suggestions = this.latest_suggestions.then(() =>
      getPlacePredictions(input, this.props.specificity, this.sessionToken)
        .then(suggestions => {
          this.setState({ suggestions });
        })
        .catch(err => {
          console.warn(err);
        })
    );
  }

  getPlaceDetails(place) {
    return this.latest_details = this.latest_details.then(() => getPlaceDetails(place))
      .then(res => {
        this.setState({
          value: res.formatted_address,
        });
        this.parsePlace(res);
      })
    ;
  }

  onFocus(e) {
    if (this.props.input && typeof this.props.input.onFocus === 'function') {
      this.props.input.onFocus(e);
    }
    this.setState({ hasFocus: true });
  }

  onBlur() {
    this.setState({ hasFocus: false });
    if (this.props.input && typeof this.props.input.onBlur === 'function') {
      this.props.input.onBlur();
    }
    if (this.state.hasEditedSinceSelect && !this.justClicked) {
      if (this.state.suggestions && this.state.suggestions.length) {
        this.getPlaceDetails(this.state.suggestions[0]);
      }
      else {
        if (this.state.value && this.props.setValueWithoutConfirming) {
          this.props.input.onChange(this.state.value);
        }
      }
    }
    this.justClicked = false;
  }

  onChange(e) {
    if (e.target.value !== '' && e.target.value !== this.state.value) {
      this.updateSuggestions(e.target.value);
    }
    let hasEditedSinceSelect = true;
    if (e.target.value === '') {
      this.props.input.onChange({});
      hasEditedSinceSelect = false;
    }
    this.setState({
      value: e.target.value,
      suggestions: null,
      hasEditedSinceSelect,
    });
  }

  onKeyDown(e) {
    if (e.keyCode === 13) { // enter
      const { suggestions } = this.state;
      if (suggestions && suggestions.length > 0) {
        this.getPlaceDetails(suggestions[0])
          .then(() => this.input.blur())
        ;
      }
    }
    if (e.keyCode === 8 && e.target.value === '') {
      // clear our value
      this.props.input.onChange({});
      this.setState({ hasEditedSinceSelect: false });
    }
  }

  onClickHistory(e, place) {
    this.setState({
      hasEditedSinceSelect: false,
    });
    this.updateHistory(place);
    this.props.input.onChange(place);
  }

  onClickPlace(e, place) {
    this.justClicked = true;
    this.getPlaceDetails(place);
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  onClickAnyway() {
    this.props.input.onChange(this.state.value);
    this.justClicked = true;
  }

  render() {
    const {
      input,
      meta,
      label,
      labelProps = {},
      inputProps = {},
      placeholder,
      horizontal = false,
      shouldError = (meta = {}) => meta.error && (meta.dirty || meta.touched),
      specificity,
      setValueWithoutConfirming,
      errorStyle = {},
      ...rest
    } = this.props;
    const { hasFocus, value, suggestions } = this.state;

    return (
      <div
        {...rest}
        className={cs(
          'GoogleLocationInput form-group',
          {
            'has-error': shouldError(meta),
          },
          rest.className,
        )}
        onBlur={this.onBlur}
        >
        {
          label ?
            <label
              {...labelProps}
              className={cs(
                'control-label',
                labelProps.className,
                {
                  'col-xs-2': horizontal,
                },
              )}
              >
              {label}
            </label>
            : null
        }
        <div
          style={{ position: 'relative' }}
          className={cs({
            'col-xs-10': horizontal,
          })}
          >
          <input
            {...inputProps}
            className={cs('form-control', inputProps.className)}
            ref={ref => this.input = ref}
            placeholder={placeholder}
            onFocus={this.onFocus}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            value={value}
          />
          {
            hasFocus ?
              (
                value === '' && this.history && this.history.length ?
                  <div className='GoogleLocationInput__history pac-container pac-logo'>
                    {this.history.map(place =>
                      <div key={place.place_id} role='button' title={place.formatted_address} className='pac-item' onMouseDown={e => this.onClickHistory(e, place)}>
                        <MaterialIcon size={20} name='access_time' className='pac-icon' />
                        <span className='pac-item-query'>{place.formatted_address}</span>
                      </div>
                    )}
                  </div>
                  :
                  (
                    value !== '' && suggestions && suggestions.length ?
                      <div className='GoogleLocationInput__history pac-container pac-logo'>
                        {suggestions.map(place =>
                          <div key={place.place_id} role='button' title={place.description} className='pac-item' onMouseDown={e => this.onClickPlace(e, place)}>
                            <MaterialIcon size={20} name='place' className='pac-icon' />
                            <span className='pac-item-query'>{place.description}</span>
                          </div>
                        )}
                      </div>
                      : null
                  )
              )
              : null
          }
        </div>
        {
          shouldError(meta) ?
            Array.isArray(meta.error) ?
              meta.error.map((err, i) => <div key={i} style={errorStyle} className='help-block'>{err}</div>)
              : <div style={errorStyle} className='help-block'>{meta.error}</div>
            : null
        }
      </div>
    );
  }
}

export default GoogleLocationInput;
