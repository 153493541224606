import React from 'react';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { Button } from 'react-bootstrap';
import Header from 'components/pure/Header';
import DropdownList from 'react-widgets/lib/DropdownList';
import { compose, withStateHandlers } from 'recompose';
import { edit } from 'actions/resource/factoringpayment';
import { openModal, closeModal } from 'actions/ui';
import formatError from 'helpers/formatError';
import { initiateAdd } from 'actions/post';
import { push } from 'react-router-redux';
import isDispatchBroker from 'selectors/isDispatchBroker';
import LabeledInput from '../../../pure/form/inputs/LabeledInput';
import TransactionReportButton from '../../../pure/TransactionReportButton';
import { get, isEmpty, pick, reduce, concat } from 'lodash';
import { browserHistory } from 'react-router-v4';
import PopupCalendarInput from 'components/pure/form/inputs/PopupCalendarInput';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import { getBrokerGroupCarriers } from 'actions/groups';
import { FUNDING_REQUEST_STATUS } from "../../../../helpers";
import MaterialIcon from 'components/pure/MaterialIcon';
import MultiFundingRequestTypeInput from '../../../pure/form/inputs/FundingRequestMultiSelect';

const styles = {
  flexContainer: {
    display: 'flex',
    marginTop: 5,
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  labelStyle: {
    fontSize: '1em',
    fontTransform: 'capitalize',
  },
};
const FundingRequestTypeMapping = {
  STD_BROKER: 'Factored',
  SELF_FINANCE_NO_INVOICE: 'Self-Financed No Invoicing',
  SELF_FINANCE_WITH_INVOICE: 'Self-Financed With Invoicing',
}
const FundingRequestType = props => {
  if(props.useNewNFRequest) {
      props.enabledContractTypes['NON_FACTORED_BILL_OUT'] = true;
      props.enabledContractTypes['NON_FACTORED_STANDARD'] = true;
      props.enabledContractTypes['SELF_FINANCE_NO_INVOICE'] = true;
      props.enabledContractTypes['SELF_FINANCE_WITH_INVOICE'] = true;
  }
  const disabledOptions = reduce(props.enabledContractTypes, (results, value, key) => !value ? concat(results, [key]) : results, []);
  let data = [{
    text: 'Factored',
    value: 'STD_BROKER',
    type: 'Standard ',
  }, {
    text: 'Self-Financed No Invoicing',
    value: 'SELF_FINANCE_NO_INVOICE',
    type: 'Non Factored',
  }, {
    text: 'Self-Financed With Invoicing',
    value: 'SELF_FINANCE_WITH_INVOICE',
    type: 'Non Factored',
  }];

  if(props.useNewNFRequest) {
      data.push({
           text: 'Non-Factored BOL',
           value: 'NON_FACTORED_BILL_OUT',
           type: 'Non Factored',
      });
      data.push({
           text: 'Non-Factored Carrier',
           value: 'NON_FACTORED_STANDARD',
           type: 'Non Factored',
      });
  }

  return (
    <DropdownList
      disabled={disabledOptions}
      name='contract_type'
      data={data}
      groupBy={FRType => FRType.type}
      onChange={object => {
        switch (object.value) {
          case 'SELF_FINANCE_NO_INVOICE':
            props.setType({ self_finance_requested: true, contract_type: 'SELF_FINANCE_NO_INVOICE' });
            break;
          case 'SELF_FINANCE_WITH_INVOICE':
            props.setType({ self_finance_requested: true, contract_type: 'SELF_FINANCE_WITH_INVOICE' });
            break;
          default:
            props.setType({ self_finance_requested: false, contract_type: 'STD_BROKER' });
            break;

        }

      }}
      textField='text'
      valueField='value'
      {...props}
    />
  );
};

const STATUS_MAPPING = {
  incomplete: 'Incomplete',
  special_pending: 'Pending',
  declined: 'Declined',
  document_issue: 'Document Issue',
  remote_approved: 'Approved'
}

const dropDownData = isDispatchBroker => [
  {
    text: 'Incomplete',
    value: isDispatchBroker ? 'incomplete' : 'pending_delivery',
  },
  {
    text: 'Pending',
    value: 'special_pending',
  },
  {
    text: 'Declined',
    value: 'declined',
  },
  {
    text: 'Document Issue',
    value: 'document_issue',
  },
  {
    text: 'Approved',
    value: 'remote_approved',
  },
];

const onClickSetStatus = (status, selectedItems, setErrors, selectedFundingRequest) => async dispatch => {
    const errors = [];
    if (selectedItems.length > 0) {
      for (const funding_request_id in selectedItems) {
        let pass = false;
        const fundingRequest = get(selectedFundingRequest, [selectedItems[funding_request_id], 'data'], {});
        let carrier = {};
        if (status === 'remote_approved') {
          const res = await dispatch(
            getBrokerGroupCarriers(get(fundingRequest, 'factoring_id'), {
              payment_profile_id: get(fundingRequest, 'payment_profile.id', '')
            })
          );
          if ((res.results || []).length) {
            carrier = get(res, 'results[0]', {});
          }
        }
        if (
          !isEmpty(carrier) &&
          status === 'remote_approved' &&
          (carrier.payout_days !== fundingRequest.payout_days ||
            carrier.factoring_fee_split_rate !== fundingRequest.factoring_fee_split_rate)
        ) {
          await new Promise((resolve, reject) =>
            dispatch(
              openModal('ConfirmationFRPaymentSpeed', {
                modalSize: 'small',
                fundingRequestId: fundingRequest.id,
                invoiceNumber: fundingRequest.invoice_number,
                load: fundingRequest.user_load_number,
                invoiceAmount: fundingRequest.amount,
                carrierName: fundingRequest.payment_profile.company_name,
                defaultValue: {
                  payout_days: carrier.payout_days,
                  factoring_fee_split_rate: carrier.factoring_fee_split_rate
                },
                initialValue: {
                  payout_days: fundingRequest.payout_days,
                  factoring_fee_split_rate: fundingRequest.factoring_fee_split_rate
                },
                currentValue: {
                  payout_days: fundingRequest.payout_days,
                  factoring_fee_split_rate: fundingRequest.factoring_fee_split_rate
                },
                handleSubmit: () => {
                  dispatch(closeModal());
                  setTimeout(() => resolve(true), 200);
                },
                closeModal: () => {
                  dispatch(closeModal());
                  setTimeout(() => resolve(true), 200);
                  pass = true;
                },
              })
            )
          );
        }
        try {
          if(!pass) {
            await dispatch(edit(selectedItems[funding_request_id], { status }, { bulk: true }));
          }
        } catch (e) {
          errors.push({
            fundingRequestId: selectedItems[funding_request_id],
            errorMessage: formatError(e, 'Unable to Submit Status Change')
          })
        }
      }
      if (isEmpty(errors)) {
        dispatch(openModal('success', { message: `Successfully set status to ${STATUS_MAPPING[status]}` }));
      } else {
        dispatch(openModal('warning', {
          message: <div>
            <span>
              The following funding request(s) could not be updated to approved,
              please hover over the warning icon(
            </span>
            <MaterialIcon name='warning' size={16} style={{ color: '#bb2124' }} />
            <span>
              ) for details as to why the funding requests could not have its status updated.
            </span>
          </div>
        }));
      }
      setErrors(errors)
  }
};

const onClickSetType = (payload, selectedItems, setErrors, selectedFundingRequest) => async dispatch => {
  try {
    const errors = [];
    if (selectedItems.length > 0) {
      for (const funding_request_id in selectedItems) {
        const fundingRequest = get(selectedFundingRequest, [selectedItems[funding_request_id], 'data'], {});
        if (fundingRequest.status === USER_FACTORING_STATUS.REMOTE_APPROVED) {
          errors.push(selectedItems[funding_request_id])
          setErrors(errors)
        } else {
          await dispatch(edit(selectedItems[funding_request_id], payload, { bulk: true }));
        }
      }
      if (isEmpty(errors)) {
        dispatch(openModal('success', { message: `Successfully set Type to ${FundingRequestTypeMapping[payload.contract_type]}` }));
      } else {
        dispatch(openModal('warning', {
          message: <div>
            <span>
              The following funding request(s) could not be updated to approved,
              please hover over the warning icon(
            </span>
            <MaterialIcon name='warning' size={16} style={{ color: '#bb2124' }} />
            <span>
              ) for details as to why the funding requests could not have its status updated.
            </span>
          </div>
        }));
      }
    }
  }
  catch (e) {
    dispatch(openModal('error', { message: formatError(e, 'Unable to Submit Status Change') }));
  }
};

const TransactionHeader = ({ path, selectedFundingRequest, errors, setErrors, setReportDateRange, reportDateRange, setReportValue, reportValue = 'funding_report', setSearchValue, searchValue, status, setStatus, selectedItems, dispatch, initiateAdd, push, isDispatchBroker, setFilter, factoringId, allowPendingReserveAccess, canCreateSelfFinanceFr, setType, enabledContractTypes, stripeEnabled, useNewNFRequest, fundingRequestTypeValue, setFundingRequestType, ...props }) =>
  <div style={{ marginBottom: 5 }}>
    <div className='col-xs-6'>
      <Header>Transactions</Header>
    </div>
    <div className='col-xs-1 col-md-4' />
    <div className='col-xs-5 col-md-2' style={{ ...styles.flexContainer, ...styles.alignRight }}>
      <Button className='btn btn-blue' style={{ minWidth: '8.3em' }} onClick={() => push({ pathname: '/postload/all-loads', state: { postLoad: true } })} >Create Load</Button>
    </div>
    <div className='col-xs-12' style={styles.flexContainer}>
      <div
        style={{
          display: 'flex',
          flex: 0.2,
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          <TransactionReportButton factoringId={factoringId} reportValue={reportValue} reportDateRange={reportDateRange} stripeEnabled={stripeEnabled} />
          <DropdownList
            data={[
              {
                text: 'Funding Report',
                value: 'funding_report',
              },
              {
                text: 'Carrier Funding Report',
                value: 'carrier_funding_report',
              },
              ...(() => allowPendingReserveAccess ? [{ value: "pending_reserve_report", text: "Pending Chargeback/Giveback Report" }] : [])(),
              {
                text: 'Broker Funding Report',
                value: 'broker_funding_report',
              },
              {
                text: 'AR Report',
                value: 'ar_report',
              },
              {
                text: 'Open AR Report',
                value: 'open_ar_report',
              },
              {
                text: 'Debtor Aging',
                value: 'debtor_aging_by_bucket',
              },
              {
                text: 'Invoice Settlement Report',
                value: 'settlement_report'
              },
              {
                text: 'Chargeback / Giveback Report',
                value: 'chargeback_giveback_report'
              },
              ...(() => canCreateSelfFinanceFr ? [{ value: "self_finance_report", text: "Self-Finance Funding Report" }, { value: "wallet_transactions_report", text: "Wallet Transactions Report" }] : [])(),
            ]}
            textField='text'
            valueField='value'
            value={reportValue}
            onChange={val => {
              setReportValue(val.value);
            }}
            style={{ minWidth: '300px', margin: '0px 10px' }}
          />
        </div>
        <div style={{ paddingTop: 20 }}>
          <PopupCalendarInput
            name='range'
            input={{
              value: '',
              onChange: setReportDateRange,
              onFocus: () => ({}),
              onBlur: () => setReportDateRange,
            }}
            onConfirm={() => ({})}
            buttonText="Filter"
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
        }}
      >
        {/*Funding Report
          Carrier Funding Report
          Broker Funding Report
          AR Report
          Open AR Report
          Debtor Aging*/}
        <div
          style={{
            float: 'right',
            display: 'flex',
          }}
        >
          <DropdownList
            data={dropDownData(isDispatchBroker)}
            textField='text'
            valueField='value'
            value={status}
            onChange={val => {
              setStatus(val.value);
            }}
            style={{ minWidth: '150px', margin: '0px 10px' }}
          />
          <Button className='btn btn-orange' style={{ minWidth: '8.3em', ...styles.alignRight }} onClick={() => onClickSetStatus(status, selectedItems, setErrors, selectedFundingRequest)(dispatch)} >
            Set Status
          </Button>
        </div>
        {(useNewNFRequest || (canCreateSelfFinanceFr && !['remote_approved', 'declined', 'carrier_paid', 'invoice_paid'].includes(path))) && (
          <div
            className='col-xs-12'
            style={{
              margin: '10px 10px',
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <FundingRequestType
                setType={setType}
                value={props.fundingRequestType.contract_type}
                canCreateSelfFinanceFr={canCreateSelfFinanceFr}
                enabledContractTypes={enabledContractTypes}
                useNewNFRequest={useNewNFRequest}
                style={{ minWidth: '200px', margin: '0px 10px' }}
              />
              <Button className='btn btn-orange' style={{ minWidth: '8.3em', ...styles.alignRight }} onClick={() => onClickSetType(props.fundingRequestType, selectedItems, setErrors, selectedFundingRequest)(dispatch)} >
                Set Type
              </Button>
            </div>
          </div>
        )
        }
      </div>
    </div>
      {!isDispatchBroker && (
          <div
              style={{
                  width: '100%'
              }}
          >
          <div
          className='col-xs-12'
          style={{
            padding: '10px 10px',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              padding: '0px 10px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LabeledInput
              style={{ minWidth: 350 }}
              placeholder='Search by load number or invoice number or Po number'
              onChange={event =>
                setSearchValue(event.target.value)}
            />
          </div>
          <div
            style={{
              // padding: '10px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              className='btn btn-payment'
              onClick={() => {
                if (isEmpty(searchValue)) {
                  return browserHistory.goBack();
                }
                browserHistory.push('/haul-pay/transaction/search');
                setFilter({ load_or_invoice_or_debtor_or_status: searchValue, status: '' });
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    )}
    {useNewNFRequest && (
        <div
            className="col-xs-12"
            style={{
                margin: '10px 10px',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'flex-end'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <MultiFundingRequestTypeInput
                  value={fundingRequestTypeValue}
                  onChange={value => {
                    setFundingRequestType(value)
                    if (isEmpty(value)) {
                        return browserHistory.goBack();
                    }
                    browserHistory.push('/haul-pay/transaction/search');
                    setFilter({ contract_type: value.map(v => v.value).join(','), load_or_invoice_or_debtor_or_status: '', status: '' });
                  }}
                />
            </div>
        </div>
    )}
  </div>;

export default compose(
  withStateHandlers(({ fundingRequestType = { contract_type: '', self_finance_requested: false }, status = 'special_pending', searchValue = '', reportValue = 'funding_report', reportDateRange = {}, fundingRequestTypeValue = [] }) => ({ status, fundingRequestType, fundingRequestTypeValue }), {
    setStatus: () => status => ({ status }),
    setType: () => fundingRequestType => ({ fundingRequestType }),
    setFundingRequestType: () => fundingRequestTypeValue => ({ fundingRequestTypeValue }),
    setReportValue: () => reportValue => ({ reportValue }),
    setSearchValue: () => searchValue => ({ searchValue }),
    setReportDateRange: () => reportDateRange => ({
      reportDateRange: {
        created_gte: reportDateRange.start ? reportDateRange.start.toISOString() : null,
        created_lte: reportDateRange.end ? reportDateRange.end.toISOString() : null,
      }
    }),
  }),
  connect((state, { selectedItems }) => ({
    selectedFundingRequest: pick(get(state, ['resource', 'factoringpayment'], {}), selectedItems),
    isDispatchBroker:
      isDispatchBroker(state),
    canCreateSelfFinanceFr: get(state, `user.factoring.data.allow_self_finance_fr`, false),
    stripeEnabled: get(state, `user.factoring.data.stripe_enabled`, false),
    factoringId: get(state, ['resource', 'user', state.user.id, 'data', 'factoring_id']),
    allowPendingReserveAccess: get(state, ['user', 'factoring', 'data', 'allow_pending_reserve_access']),
    enabledContractTypes: get(state, `user.factoring.data.enabled_contract_types`, false),
    useNewNFRequest: get(state, `user.factoring.data.use_new_non_factored_funding_requests`, false),
  }),
    dispatch => ({ dispatch, initiateAdd: status => dispatch(initiateAdd('load', status)), push: (...args) => dispatch(push(...args)) }))
)(TransactionHeader);
